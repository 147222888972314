import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { InputBase } from "@mui/material";
import { ArrowRightXlIcon } from "../assets/SvgIcons";
import AffiliateProgramLink from "../components/AffiliateProgramLink";
import { useForm } from "react-hook-form";
import { STORAGE } from "../config/config.js";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useUser } from "../context/UserContext .js";
import { Helmet } from "react-helmet";
import TopBar from "../components/TopBar.jsx";
import Header from "../components/Header.jsx";
import Footer from "../components/Footer.jsx";
import { LoginDrawerContext } from "../context/LoginDrawerContext.js";

const AffiliateProgramPage = () => {
  const { handleSubmit, reset, setValue } = useForm();
  const { userDetails, setUserDetails } = useUser();
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    fullName: "",
    accountNumber: "",
    contactNumber: "",
    ifscCode: "",
    email: "",
    branchName: "",
    bankName: "",
    upiId: "",
  });
  const [errors, setErrors] = useState({});
  const [showAffiliateLink, setShowAffiliateLink] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    accountNumber: Yup.string().required("Account Number is required"),
    contactNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Contact Number must be 10 digits")
      .required("Contact Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    branchName: Yup.string().required("Branch Name is required"),
    bankName: Yup.string().required("Bank Name is required"),
    upiId: Yup.string().required("UPI ID is required"),
  });

  const fetchUserDetails = async () => {
    const userProfile = JSON.parse(localStorage?.getItem(STORAGE?.USERDETAIL));
    const deviceId = localStorage.getItem(STORAGE?.DEVICEID);
    try {
      setLoading(true);
      const { data } = await axios.post("/userdetail", {
        user_id: userProfile?.id,
        device_id: deviceId,
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
      });

      if (data && data?.STATUS === 200) {
        const {
          user_name,
          user_last_name,
          user_email,
          user_mobile,
          user_profile,
          is_affiliate,
        } = data?.DATA;

        setUserDetails({
          user_first_name: user_name,
          user_last_name: user_last_name,
          user_email: user_email,
          user_mobile: user_mobile,
          user_profile: user_profile,
        });
        setValue("user_first_name", user_name);
        setValue("user_last_name", user_last_name);
        setValue("user_email", user_email);
        setValue("user_mobile", user_mobile);
        setShowAffiliateLink(is_affiliate);
      }
    } catch (err) {
      // console.error("Error fetching user details:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "contactNumber" && value.length > 10) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const { setShowLogin } = useContext(LoginDrawerContext);

  const isLoggedIn = localStorage.getItem(STORAGE?.ISLOGIN);
  const [pendingFormData, setPendingFormData] = useState(null);

  useEffect(() => {
    if (isLoggedIn && pendingFormData) {
      handleFormSubmission(pendingFormData);
      setPendingFormData(null); // Clear after submission
    }
  }, [isLoggedIn, pendingFormData]);

  // const onSubmit = async () => {
  //   try {
  //     await validationSchema.validate(formData, { abortEarly: false });

  //     const response = await AffiliateProgramSubmit();
  //     if (isLoggedIn) {
  //       if (response.status === 200) {
  //         resetForm();
  //         toast.success("Form submitted successfully!");
  //         setResponseMessage(response.data.message);
  //         setShowAffiliateLink(true);
  //       } else {
  //         setResponseMessage(response.data.message);
  //         toast.error("Form submission failed. Please try again.");
  //       }
  //     } else {
  //       setShowLogin(true);
  //     }
  //   } catch (error) {
  //     if (error.inner) {
  //       const newErrors = {};
  //       error.inner.forEach((err) => {
  //         newErrors[err.path] = err.message;
  //       });
  //       setErrors(newErrors);
  //     } else {
  //       toast.error("Form submission failed. Please try again.");
  //     }
  //   }
  // };

  const handleFormSubmission = async (formData) => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await AffiliateProgramSubmit(formData);
      if (response.status === 200) {
        resetForm();
        toast.success("Form submitted successfully!");
        setResponseMessage(response.data.message);
        setShowAffiliateLink(true);
      } else {
        setResponseMessage(response.data.message);
        toast.error("Form submission failed. Please try again.");
      }
    } catch (error) {
      if (error.inner) {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        toast.error("Form submission failed. Please try again.");
      }
    }
  };

  // const onSubmit = async () => {
  //   if (isLoggedIn) {
  //     await handleFormSubmission(formData);
  //   } else {
  //     // Store form data and show login drawer
  //     setPendingFormData(formData);
  //     setShowLogin(true); // Open login drawer
  //   }
  // };

  const onSubmit = async () => {
    try {
      // Validate form data using Yup schema
      await validationSchema.validate(formData, { abortEarly: false });
  
      if (isLoggedIn) {
        await handleFormSubmission(formData);
      } else {
        setPendingFormData(formData);
        setShowLogin(true); // Open login drawer
      }
    } catch (error) {
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      }
      // Optionally show a notification for incomplete fields
      toast.error("Please fill in all required fields.");
    }
  };

  const AffiliateProgramSubmit = async (formData) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const response = await axios.post("/affeliateformsave", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        is_admin: "0",
        user_id: userProfile?.id,
        name: formData?.fullName,
        contact_number: formData?.contactNumber,
        email: formData?.email,
        bank_name: formData?.bankName,
        account_number: formData?.accountNumber,
        IFSC_code: formData?.ifscCode,
        branch_name: formData?.branchName,
        UPI_id: formData?.upiId,
      });
      return response;
    } catch (error) {
      console.error("Error submitting affiliate program:", error);
      throw error;
    }
  };

  const resetForm = () => {
    setFormData({
      fullName: "",
      accountNumber: "",
      contactNumber: "",
      ifscCode: "",
      email: "",
      branchName: "",
      bankName: "",
      upiId: "",
    });
    setErrors({});
  };

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      Affiliate Program
    </p>,
  ];

  return (
    <>
      <Helmet>
        <title>Affiliate Program - Kapoorlehengasaree</title>
        <meta
          name="description"
          content="Join our global affiliate program and earn commissions by promoting stylish and trendy women's clothing from our renowned e-commerce store. "
        />
        <meta property="og:title" content="Affiliate Program" />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="Join our global affiliate program and earn commissions by promoting stylish and trendy women's clothing from our renowned e-commerce store"
        />
        <meta
          property="og:url"
          content="https://kapoorlehengasaree.com/affiliate-program"
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="Affiliate Program" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="Join our global affiliate program and earn commissions by promoting stylish and trendy women's clothing from our renowned e-commerce store"
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href="https://kapoorlehengasaree.com/affiliate-program"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div
        className="bg-repeat-x bg-[top_center] w-full py-8 md:py-14 max-w-[1836px] mx-auto"
        style={{ backgroundImage: "url(/images/affiliate-program-bg.png)" }}
      >
        <div className="max-w-[1418px] mx-auto px-3">
          {showAffiliateLink ? (
            <AffiliateProgramLink />
          ) : (
            <div className="bg-white px-5 md:px-8 xl:pl-[60px] xl:pr-[75px] py-[30px] border-2 border-[#ECEAEA]">
              <div className="w-full bg-white mb-10 xl:mb-[70px]">
                <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-6 md:mb-10 xl:mb-[70px]">
                  Affiliate Program
                </h2>
                <div className="flex flex-wrap -mx-3 gap-y-3">
                  <div className="w-full sm:w-4/12 px-3 text-center relative">
                    <div className="mb-3 md:mb-6">
                      <img
                        src="/images/users.png"
                        className="mx-auto w-[20%]"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="sm:text-sm lg:text-lg !leading-[1.31] text-[#494848] max-w-[257px] mx-auto">
                      Refer your code Friends & Family
                    </p>
                    <span className="hidden sm:block absolute -right-5 top-[9%]">
                      <ArrowRightXlIcon />
                    </span>
                  </div>
                  <div className="w-full sm:w-4/12 px-3 text-center relative">
                    <div className="mb-3 md:mb-6">
                      <img
                        src="/images/add-friend.png"
                        className="mx-auto w-[20%]"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="sm:text-sm lg:text-lg !leading-[1.31] text-[#494848] max-w-[300px] mx-auto">
                      Your Friend Signup with your Referral Code
                    </p>
                    <span className="hidden sm:block absolute -right-5 top-[9%]">
                      <ArrowRightXlIcon />
                    </span>
                  </div>
                  <div className="w-full sm:w-4/12 px-3 text-center">
                    <div className="mb-3 md:mb-6">
                      <img
                        src="/images/commission.png"
                        className="mx-auto w-[20%]"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                    <p className="sm:text-sm lg:text-lg !leading-[1.31] text-[#494848] max-w-[361px] mx-auto">
                      You earn Commission every Time when your friend Shopping
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <form action="#" onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-5 lg:gap-y-[35px] gap-x-5 lg:gap-x-[30px] 2xl:gap-x-20">
                    <div className="w-full">
                      <InputBase
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Full Name"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.fullName && (
                        <div className="text-[red]">{errors.fullName}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Account Number"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.accountNumber && (
                        <div className="text-[red]">{errors.accountNumber}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        type="number"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Contact Number"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.contactNumber && (
                        <div className="text-[red]">{errors.contactNumber}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="ifscCode"
                        value={formData.ifscCode}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="IFSC Code"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.ifscCode && (
                        <div className="text-[red]">{errors.ifscCode}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Email id"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.email && (
                        <div className="text-[red]">{errors.email}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="branchName"
                        value={formData.branchName}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Branch Name"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.branchName && (
                        <div className="text-[red]">{errors.branchName}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="Bank Name"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.bankName && (
                        <div className="text-[red]">{errors.bankName}</div>
                      )}
                    </div>
                    <div className="w-full">
                      <InputBase
                        name="upiId"
                        value={formData.upiId}
                        onChange={handleChange}
                        classes={{ input: "py-0" }}
                        sx={{
                          "&::placeholder": { color: "#464646 !important" },
                        }}
                        placeholder="UPI ID"
                        className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6 text-black !placeholder:text-[#464646]"
                      />
                      {errors.upiId && (
                        <div className="text-[red]">{errors.upiId}</div>
                      )}
                    </div>
                  </div>
                  <div className="w-full flex justify-center mt-10 xl:mt-20">
                    <button
                      type="submit"
                      variant="contained"
                      className="w-full max-w-[532px] h-12 bg-[#E9B159] hover:bg-[#E9B159] text-white text-xl font-jost uppercase font-bold"
                    >
                      Submit
                    </button>
                  </div>
                </form>
                {responseMessage && (
                  <div className="mt-6 text-center text-xl text-green-600">
                    {responseMessage}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AffiliateProgramPage;
