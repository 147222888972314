import toast from "react-hot-toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  CashOnDelIcon,
  ExchangeIcon,
  ShippingIcon,
  StitchingIcon,
} from "../assets/SvgIcons";
import { TiStarFullOutline } from "react-icons/ti";
import Breadcrumb from "../components/Breadcrumb";
import { FaAngleDown, FaRegCircleCheck, FaStar } from "react-icons/fa6";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import QuantityCounter from "../components/QuantityCounter";
import { RiHeartAddLine } from "react-icons/ri";
import { TbHeartPlus } from "react-icons/tb";
import { IoBagOutline } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import ProductDetailsSlider from "../components/ProductDetailsSlider";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { TextareaAutosize } from "@mui/material";
import { Rating } from "@mui/material";
import { FaXmark } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";
import { AddPhotoIcon } from "../assets/SvgIcons";
import CustomerReview from "../components/CustomerReview";
import SuggestionBlock from "../components/SuggestionBlock";
import axios from "axios";
import addToWishlist from "../utils/addToWishlist";
import { CartContext } from "../context/CartContext";
import { LoginDrawerContext } from "../context/LoginDrawerContext";
import { STORAGE } from "../config/config";
import shoppingBag from "../assets/images/shopping-bag.png";
import shopCart from "../assets/images/shop-cart.png";
import shopBag from "../assets/images/shop-bag.png";
import { WishlistContext } from "../context/WishlistContext";
import { Circles } from "react-loader-spinner";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Tooltip as ReactTooltip } from "react-tooltip";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 25,
  border: "1px solid #B0B0B0",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 25,
    backgroundColor: "#11B455",
  },
}));

const ProductDetailsPage = () => {
  const { id } = useParams();

  const { fetchCartItems } = useContext(CartContext);
  const { fetchWishlistItem, removeFromWishlist } = useContext(WishlistContext);

  const [productInfo, setProductInfo] = useState([]);

  const [stitchingOptions, setStitchingOptions] = useState([]);

  const [selectedColor, setSelectedColor] = useState("");

  const navigate = useNavigate();

  const [quantity, setQuantity] = useState(1);
  const { setShowLogin } = useContext(LoginDrawerContext);
  const [loading, setLoading] = useState(true);

  const fetchProductInfo = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      setLoading(true);
      const { data } = await axios.post("/productdetail", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        product_quantity: quantity || 1,
        is_mobile: "0",
        product_id: id,
        is_admin: "0",
      });

      if (data && data?.STATUS === 200) {
        setProductInfo(data?.DATA);

        setStitchingOptions(data?.DATA?.stiching_price);
        if (data?.DATA?.colorist_images?.length > 0) {
          const firstColor = data?.DATA?.colorist_images[0];
          setSelectedColor(firstColor.varient_name);
          setSelectedColorImages(firstColor.multiple_image);
        } else {
          setSelectedColorImages(data?.DATA?.default_images);
        }
      }
      console.log("productInfo", data?.DATA);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const [expanded, setExpanded] = useState("panel1");

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (id) {
      fetchProductInfo();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  const breadcrumbArray = [
    <Link
      to={"/"}
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <Link
      to={"/product-page"}
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Product Page
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Product Details
    </p>,
  ];

  const colorOptions = [
    {
      color: "purple",
      image: "/images/products/purplecholi.jpeg",
    },
    {
      color: "black",
      image: "/images/products/blackcholi.jpeg",
    },
    {
      color: "blue",
      image: "/images/products/bluecholi.jpeg",
    },
    {
      color: "orange",
      image: "/images/products/orangecholi.jpeg",
    },
    {
      color: "green",
      image: "/images/products/greencholi.jpeg",
    },
  ];

  const bagShowOff = [
    {
      icon: <CashOnDelIcon />,
      title: "CASH ON DELIVERY",
    },
    {
      icon: <ExchangeIcon />,
      title: "SIMPLE EXCHANGE",
    },
    {
      icon: <StitchingIcon />,
      title: "SIMPLE EXCHANGE",
    },
    {
      icon: <ShippingIcon />,
      title: "SIMPLE EXCHANGE",
    },
  ];
  const [openWriteReview, setOpenWriteReview] = useState(false);
  const handleWriteReviewOpen = () => setOpenWriteReview(true);
  const [selectedColorImages, setSelectedColorImages] = useState([]);
  const [ratingValue, setRatingValue] = useState(0);

  const handleRatingChange = (event, newValue) => {
    setRatingValue(newValue);
  };

  const handleWishlistClick = (id) => {
    addToWishlist(id);
  };

  const [isWishlisted, setIsWishlisted] = useState(false);

  const handleAddToWishlist = async (id) => {
    const added = await addToWishlist(id);
    if (added) {
      setIsWishlisted(true);
      fetchWishlistItem();
    }
  };

  const handleRemoveFromWishlist = async (id) => {
    const removed = await removeFromWishlist(id);
    if (removed) {
      setIsWishlisted(false);
      fetchWishlistItem();
    }
  };

  const isLoggedIn = localStorage.getItem(STORAGE?.ISLOGIN);

  useEffect(() => {
    if (productInfo?.is_wishlist) setIsWishlisted(true);
    else setIsWishlisted(false);
  }, [productInfo]);



  const handleAddToCartClick = async (id) => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    console.log("AddTOCart", {
      user_type: userProfile?.user_type ?? STORAGE?.B2C,
      device_id: localStorage.getItem(STORAGE?.DEVICEID),
      is_mobile: "0",
      product_id: id,
      product_quantity: quantity || 1,
      stching: stitchingValue,
      is_admin: "0",
      user_id: userProfile?.id,
    });
    try {
      const { data } = await axios.post("addtocart", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        is_mobile: "0",
        product_id: id,
        product_quantity: quantity || 1,
        stching: stitchingValue,
        is_admin: "0",
        user_id: userProfile?.id,
      });
      console.log("flsf::", data);
      if (data && data?.STATUS === 200) {
        fetchCartItems();
        fetchWishlistItem();
        toast.success(data?.MESSAGE || "Product added to cart.");
      } else if (data && data.STATUS === 400) {
        toast.error(data.MESSAGE || "Failed to add to cart.");
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Failed to add to cart.");
    }
  };

  // const [stitchingValue, setStitchingValue] = useState(0);
  const [stitchingValue, setStitchingValue] = useState("");
  const [stitchingPrice, setStitchingPrice] = useState(0);
  const [reviewMessage, setReviewMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [file, setFile] = useState([]);
  const fileInputRef = useRef(null);

  // const handleChange = (event, newValue) => {
  //   setStitchingValue(newValue);
  // };

  useEffect(() => {
    if (stitchingOptions && stitchingOptions.length > 0) {
      setStitchingValue(stitchingOptions[0].label);
      setStitchingPrice(stitchingOptions[0].price);
    }
  }, [stitchingOptions]);

  const handleChange = (event) => {
    const selectedOption = stitchingOptions.find(
      (option) => option.label === event.target.value
    );
    setStitchingValue(event.target.value);
    setStitchingPrice(selectedOption?.price || 0);
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  const handleRemoveFile = (index) => {
    const files = [...selectedFiles];
    files.splice(index, 1);
    setSelectedFiles(files);
  };

  const handleAddPhotoClick = () => {
    fileInputRef.current.click();
  };

  const handleWriteReviewClose = () => {
    setOpenWriteReview(false);
    // Reset form fields
    setStitchingValue(0);
    setReviewMessage("");
    setSelectedFiles([]);
  };

  // ------------------- privacy-policy ------------------------

  const [policy, setPolicy] = useState({});

  const fetchpolicyApi = async () => {
    try {
      const response = await axios.get("Infopages");
      setPolicy(response.data.DATA);
    } catch (error) {
      console.error("There was an error fetching the policy!", error);
    }
  };
  useEffect(() => {
    fetchpolicyApi();
  }, []);

  const [showAllOffers, setShowAllOffers] = useState(false);

  const availableOffers = productInfo?.available_offers || [];

  const visibleOffers = showAllOffers
    ? availableOffers
    : availableOffers.slice(0, 2);

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success("Code copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy code.");
      });
  };

  /*---------------------- size-chat-start ---------------------- */

  const openSizeChart = () => {
    window.open(productInfo?.sizechart, "_blank");
  };

  /*---------------------- size-chat-end ---------------------- */

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const stickyStyle = isLargeScreen
    ? {
        position: "sticky",
        top: "133px",
        height: "max-content",
        zIndex: 2,
      }
    : {};

  const truncateProductName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 10) + "...";
    }
    return name;
  };

  const truncateProductHeading = (name) => {
    if (name.length > 29) {
      return name.substring(0, 29) + "...";
    }
    return name;
  };

  const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />

      <div className="max-w-[1654px] mx-auto ">
        <div className="flex flex-wrap py-5 2xl:py-5 -mx-3 px-3 max-w-[1654px] mx-auto">
          <div
            className="w-full lg:w-[60%] px-3 lg:pr-[30px]"
            style={stickyStyle}
          >
            <ProductDetailsSlider images={selectedColorImages} />
          </div>
          <div className="w-full lg:w-[40%] px-3 lg:pl-[30px]">
            <div>
              <h1 className="my-5 font-jost text-2xl 2xl:text-2xl font-medium capitalize !leading-none  text-black">
                {productInfo?.product_name}
              </h1>
              <p className="mb-4 font-jost text-base lg:text-md font-normal capitalize !leading-[1.15]  text-[#555]">
                {productInfo?.product_detail}
              </p>
              <p className="mb-4 font-jost text-xl font-medium !leading-none  text-[#555]">
                Style No : {productInfo?.product_sku_code}
              </p>

              <div className="flex items-center gap-2.5 mb-3 md:mb-3">
                <div className="border border-[#555] rounded-[5px]  px-2 flex items-center gap-1.5">
                  <p className="font-jost text-lg sm:text-[22px] font-medium leading-[1.36]  text-black">
                    {parseFloat(productInfo?.average_rating).toFixed(1)}
                  </p>
                  <TiStarFullOutline className="text-2xl" />
                </div>
                <p className="font-jost text-xl font-medium !leading-none  text-[#555]">
                  Based on {productInfo?.total_rating} ratings
                </p>
              </div>

              <div className="pb-5">
                <h4 className="font-jost text-xl md:text-xl xl:text-[20px] font-medium !leading-none  text-black">
                  ₹{productInfo?.product_price}{" "}
                  <span className="text-[#03a685]">
                    {productInfo?.product_discount}% Off
                  </span>
                </h4>
              </div>
              <div>
                <p className="font-jost text-base lg:text-xl xl:text-[17px] font-normal !leading-none mb-3 text-[#555]">
                  MRP{" "}
                  <span className="line-through">
                    ₹{productInfo?.product_mrp}
                  </span>{" "}
                  Inclusive of all taxes
                </p>
              </div>
              {Boolean(productInfo?.colorist_images?.length) && (
                <div>
                  <div className="border-b border-b-[#acacac] my-[10px] md:my-[10px]" />
                  <div className="my-5 font-jost text-2xl 2xl:text-2xl font-medium !leading-none  text-black">
                    Select Color
                  </div>
                  <div className="flex items-center gap-3 md:gap-5 flex-wrap mb-[30px] md:mb-[30px]">
                    {productInfo?.colorist_images?.map((item, index) => {
                      return (
                        <div
                          className="relative border-[3px] h-[110px] w-20 md:h-[160px] md:w-[130px]"
                          key={item.varient_name}
                          onClick={() => {
                            setSelectedColor(item.varient_name);
                            setSelectedColorImages(item.multiple_image);
                          }}
                        >
                          <img
                            src={item.multiple_image[0]}
                            alt=""
                            className="h-full w-full object-cover"
                            loading="lazy"
                          />
                          <p className="text-center capitalize">
                            {item.varient_name}
                          </p>
                          <div
                            className={`p-1 absolute top-0 right-0 h-full w-full bg-black/30 flex justify-end ${
                              selectedColor === item.varient_name
                                ? "block"
                                : "hidden"
                            }`}
                          >
                            <FaRegCircleCheck className="text-white" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="border-b border-b-[#acacac] my-5 md:my-[12px]" />

              {userProfile?.user_type === "btoc" ? (
                <>
                  <div className="mb-[10px]">
                    <h5 className="my-2 font-jost text-xl 2xl:text-xl font-medium !leading-none text-black mb-4">
                      Available Offers:
                    </h5>
                    {visibleOffers.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="border-solid border-2 border-gray-200 mb-2"
                        >
                          <div className="flex w-full">
                            <div className="bg-[#FFEBEC] px-10 py-1 text-md font-bold relative z-40 text-red-600 rounded-r-lg w-[30%] text-center capitalize">
                              {truncateProductName(item?.title || "")}
                              <img
                                className="w-5 top-2 left-3 absolute"
                                src={shoppingBag}
                                loading="lazy"
                              />
                              <img
                                className="w-5 absolute top-4 right-3 transform rotate-45"
                                src={shopCart}
                                loading="lazy"
                              />
                              <img
                                className="w-8 absolute bottom-0 left-0"
                                src={shopBag}
                                loading="lazy"
                              />
                            </div>
                            <div className="grid items-center text-start w-[50%] ps-2">
                              <div className="text-md font-bold text-black-600">
                                {truncateProductHeading(item?.heading || "")}
                              </div>
                              <div className="text-gray-400 text-sm">
                                Use Code: {item?.name}
                              </div>
                            </div>
                            <div className="grid items-center ">
                              <button
                                className="bg-black text-white text-sm font-bold px-5 py-1 rounded-[10px] me-3"
                                onClick={() => handleCopy(item?.name)}
                              >
                                COPY
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {!showAllOffers && availableOffers.length > 2 && (
                      <div className="flex justify-end">
                        <button
                          onClick={() => setShowAllOffers(true)}
                          className="bg-gray-200 text-black text-xs font-bold px-5 py-2 rounded-[10px] mt-2"
                        >
                          See All Offers
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="border-b border-b-[#acacac] my-5 md:my-[15px]" />
                </>
              ) : (
                ""
              )}

              <div className="mb-[10px]">
                <h5 className="my-2 font-jost text-xl 2xl:text-xl font-medium !leading-none  text-black">
                  Lehenga Choli:
                </h5>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={stitchingValue}
                  onChange={handleChange}
                >
                  {stitchingOptions &&
                    stitchingOptions?.map((stitching, index) => {
                      return (
                        <div
                          key={"stitching-" + index}
                          className="flex items-center justify-between capitalize"
                        >
                          <FormControlLabel
                            value={stitching?.label}
                            control={
                              <Radio
                                sx={{
                                  "&.Mui-checked": {
                                    color: "#e9b159",
                                  },
                                }}
                              />
                            }
                            label={stitching?.label}
                            checked={stitching?.label === stitchingValue}
                          />
                          <p className="font-jost text-xl xl:text-[20px] !leading-none text-right text-[#555]">
                            ₹{stitching?.price}
                          </p>
                        </div>
                      );
                    })}
                </RadioGroup>
              </div>

              <div className="flex items-center gap-[25px] mb-[22px]">
                <h5 className="font-jost text-xl 2xl:text-xl  font-medium !leading-none  text-black">
                  Quantity:
                </h5>
                <QuantityCounter
                  onChange={(value) => setQuantity(value)}
                  quantity={quantity}
                />
                <h5 className="font-jost text-xl 2xl:text-xl font-medium !leading-none text-black">
                  SizeChat:
                  <button
                    onClick={openSizeChart}
                    className="ml-2 text-sm text-[red]"
                  >
                    {productInfo?.sizechart ? "View" : "Not available"}
                  </button>
                </h5>
              </div>

              <div className="flex gap-1 items-center">
                {isWishlisted ? (
                  <>
                    <button
                      type="button"
                      className="w-[10%] h-[30px] sm:h-[45px] flex items-center justify-center sm:text-xl md:text-base xl:text-[50px] !leading-loose font-medium p-2 sm:p-3  text-black bg-[#ededed]"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        const removed = removeFromWishlist(
                          productInfo?.id || productInfo?.product_id
                        );
                        if (removed) setIsWishlisted(false);
                      }}
                      data-tooltip-id="wishlistTooltip"
                    >
                      <FaHeart className="text-[#FF3F3F] text-lg" />
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    className="w-[10%] h-[30px] sm:h-[45px] flex items-center justify-center sm:text-xl md:text-base xl:text-[50px] !leading-loose font-medium p-2 sm:p-3  text-black bg-[#ededed]"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      if (isLoggedIn) {
                        const added = addToWishlist(
                          productInfo?.id || productInfo?.product_id
                        );
                        if (added) setIsWishlisted(true);
                        fetchWishlistItem();
                      } else {
                        setShowLogin(true);
                      }
                    }}
                    data-tooltip-id="wishlistTooltip"
                  >
                    <TbHeartPlus className="text-black text-lg" />
                  </button>
                )}
                <ReactTooltip
                  id="wishlistTooltip"
                  place="bottom"
                  content="Wishlist"
                />

                <button
                  className="w-[45%] h-[30px] sm:h-[45px]  flex items-center justify-center gap-1 sm:gap-4 sm:text-xl md:text-base xl:text-[25px] !leading-loose font-medium p-2 sm:p-3 text-white bg-[#e9b159] ps-1 btn-hover-effect"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isLoggedIn) {
                      handleAddToCartClick(
                        productInfo?.id || productInfo?.product_id
                      );
                    } else {
                      setShowLogin(true);
                    }
                  }}
                >
                  <IoBagOutline className="text-[10px] sm:text-2xl" />
                  <span className="sm:text-sm xl:text-xl">Add to Bag</span>
                </button>
                <button
                  className="w-[45%] h-[30px] sm:h-[45px] flex items-center justify-center gap-1 sm:gap-4 sm:text-xl md:text-base xl:text-[25px] !leading-loose font-medium p-2 sm:p-3 text-white bg-[#03A685] btn-hover-effect"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (isLoggedIn) {
                      handleAddToCartClick(
                        productInfo?.id || productInfo?.product_id
                      );
                      navigate("/choose-address");
                    } else {
                      setShowLogin(true);
                    }
                  }}
                >
                  <span className="sm:text-sm xl:text-xl">Buy Now</span>
                </button>
              </div>

              <div className="border-b border-b-[#acacac] my-5 md:my-[20px]" />

              <div className="bg-[#EDEDED] flex justify-between items-center p-3 mb-4 rounded">
                <div className="flex flex-col justify-center items-center text-center">
                  <img className="w-[40%]" src="/images/delivery-1.png" />
                  <div className="text-xs 2xl:text-xs w-[70%]">
                    CASH ON DELIVERY
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center">
                  <img className="w-[40%]" src="/images/exchange.png" />
                  <div className="text-xs 2xl:text-xs w-[70%]">
                    SIMPLE EXCHANGE
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center">
                  <img className="w-[40%]" src="/images/stitching.png" />
                  <div className="text-xs 2xl:text-xs w-[70%]">
                    STITCHING SERVICES
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center">
                  <img className="w-[40%]" src="/images/world-wide.png" />
                  <div className="text-xs 2xl:text-xs w-[70%] ">
                    WORLDWIDE SHIPPING
                  </div>
                </div>
                {/* ))} */}
              </div>
              <div>
                <h5 className="font-jost text-lg !leading-none text-black mb-2 capitalize ">
                  Product Information
                </h5>
                <Accordion
                  className="!shadow-none border-b border-b-[#555]"
                  expanded={expanded === "panel1"}
                  onChange={handleChangeAccordion("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div className="flex items-center gap-2 sm:gap-4">
                      <img
                        src="/images/accordion-box.png"
                        alt=""
                        className="w-8 h-8 md:w-[40px] md:h-[40px] object-contain"
                        loading="lazy"
                      />
                      <div className="">
                        <h4 className="font-jost text-lg font-medium !leading-none capitalize text-black pb-[5px]">
                          Product Details
                        </h4>
                        <p className="font-jost sm:text-sm font-normal !leading-none capitalize text-[#555]">
                          Care instructions, Pack contains
                        </p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="pl-3.5 md:pl-[80px]">
                      <div className="flex flex-wrap">
                        {productInfo?.variation?.map((item, index) => (
                          <div
                            key={item?.label + index}
                            className="w-1/2 px-3 pb-3 sm:px-3 sm:pb-3"
                          >
                            <h5 className="font-jost text-lg font-normal !leading-none capitalize  text-black mb-1.5">
                              {item?.label}
                            </h5>
                            <p className="font-jost sm:text-sm font-normal !leading-none capitalize text-[#555]">
                              {item?.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className="!shadow-none border-b border-b-[#555]"
                  expanded={expanded === "panel2"}
                  onChange={handleChangeAccordion("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <div className="flex items-center gap-2 sm:gap-4">
                      <img
                        src="/images/accordionicon3.png"
                        alt=""
                        className="w-8 h-8 md:w-[40px] md:h-[40px] object-contain"
                        loading="lazy"
                      />
                      <div className="">
                        <h4 className="font-jost text-lg font-medium !leading-none capitalize text-black pb-[5px]">
                          Know your product
                        </h4>
                        <p className="font-jost sm:text-sm font-normal !leading-none capitalize text-[#555]">
                          Description
                        </p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="pl-3.5 md:pl-[80px]">
                      <p className="font-jost text-sm font-normal leading-[1.17] capitalize text-[#555]">
                        {productInfo?.product_detail}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  className="!shadow-none border-b border-b-[#555]"
                  expanded={expanded === "panel4"}
                  onChange={handleChangeAccordion("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <div className="flex items-center gap-2 sm:gap-4">
                      <img
                        src="/images/accordion4.png"
                        alt=""
                        className="w-8 h-8 md:w-[40px] md:h-[40px] object-contain"
                        loading="lazy"
                      />
                      <div className="">
                        <h4 className="font-jost text-lg font-medium !leading-none capitalize text-black pb-[5px]">
                          Return and exchange policy
                        </h4>
                        <p className="font-jost sm:text-sm font-normal !leading-none capitalize text-[#555]">
                          Know more about return and exchange
                        </p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="pl-3.5 md:pl-[80px]">
                      <p className="font-jost text-sm font-normal leading-[1.17] capitalize text-[#555] text-justify">
                        {policy?.return_and_exchange_policy}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

        <div className="lg:px-3 max-w-[1654px] mx-auto">
          <SuggestionBlock
            title={"Similar Products"}
            info={productInfo?.similar_product}
          />
        </div>
        <div className="lg:px-3 max-w-[1654px] mx-auto">
          <SuggestionBlock
            title={"More Products For Ethnic"}
            info={productInfo?.ethnic_product}
          />
        </div>
        <div className="max-w-[1654px] mx-auto flex flex-wrap justify-between -mx-3 py-8 md:pb-10 xl:pb-[60px] border-t border-[#ACACAC] gap-y-5 px-3">
          <div className="w-full lg:w-[43%] px-3">
            <div>
              <h3 className="text-xl md:text-2xl xl:text-3xl  font-medium !leading-[1.4] text-[#242424] font-jost mb-5 md:mb-8 xl:mb-[30px]">
                Customer reviews
              </h3>
              <div className="flex flex-col lg:flex-row gap-5 items-center border-2 shadow-[0px_1px_1px_0px_#00000040] p-3 md:p-[26px] rounded-xl xl:rounded-[20px] mb-10">
                <div className="w-4/12 sm:w-4/12 lg:pe-8">
                  <h4 className="text-3xl md:text-4xl xl:text-5xl 2xl:text-[100px] text-center font-medium !leading-tight text-black">
                    {parseFloat(
                      productInfo?.product_rating?.avg_rating
                    ).toFixed(1)}
                  </h4>
                  <Rating
                    value={parseFloat(
                      productInfo?.product_rating?.avg_rating
                    ).toFixed(1)}
                    readOnly
                    icon={<FaStar className="text-lg text-[#F8C83A]" />}
                    emptyIcon={<FaStar className="text-lg text-[#D8D8D8]" />}
                    className="!mx-auto !w-full !justify-center !gap-1.5"
                    //
                  />
                </div>
                <div className="w-8/12 items-center block gap-4 lg:ps-2 lg:border-s-[2px] border-[#CFCFCF] ">
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      5
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["5"]
                        )}
                        // className="w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["5"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      4
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["4"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["4"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      3
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["3"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["3"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      2
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["2"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["2"]
                      )}
                      %
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      1
                    </span>
                    <div className="flex-shrink-0 flex-1">
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseFloat(
                          productInfo?.product_rating?.percentage_ratings["1"]
                        )}
                        // className="!w-[170px]"
                      />
                    </div>
                    <span className="flex-shrink-0 text-lg md:text-xl xl:text-2xl font-medium !leading-[1.1]">
                      {parseFloat(
                        productInfo?.product_rating?.percentage_ratings["1"]
                      )}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-3">
            <div>
              <h3 className="text-xl md:text-2xl xl:text-3xl font-medium !leading-[1.4] text-[#242424] font-jost mb-5 md:mb-8 xl:mb-[30px]">
                Top reviews from india
              </h3>
              <div
                className="grid gap-5 xl:gap-[30px] max-h-[400px] overflow-scroll p-5"
                style={{ scrollbarWidth: "none" }}
              >
                <CustomerReview />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductDetailsPage;
