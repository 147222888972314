import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { STORAGE } from "../config/config";
import bannerHomepage from "../assets/images/logo-gold.png";
import { Helmet } from "react-helmet";

const TopBar = lazy(() => import("../components/TopBar"));
const Header = lazy(() => import("../components/Header"));
const Footer = lazy(() => import("../components/Footer"));
const CategorySection = lazy(() =>
  import("../components/HomePage/CategorySection")
);
const WatchShop = lazy(() => import("../components/HomePage/WatchShop"));
const NewOnKapoorSwiper = lazy(() => import("../components/NewOnKapoorSwiper"));
const NewLaunchSwiper = lazy(() => import("../components/NewLaunchSwiper"));
const ShopByColor = lazy(() => import("../components/ShopByColor"));
const CustomerStories = lazy(() => import("../components/CustomerStories"));
const NewArrivalCard = lazy(() => import("../components/NewArrivalCard"));
const HeroSlider = lazy(() => import("../components/HeroSlider"));

const HomePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchHomePageDetails = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      setLoading(true);
      const { data } = await axios.post("/home", {
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_id: userProfile?.id,
        is_mobile: "0",
        is_admin: "0",
      });

      if (data && data.STATUS === 200) {
        setData(data.DATA || []);
        console.log("homedata", data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const divRef = useRef(null);

  useEffect(() => {
    fetchHomePageDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  return (
    <>
      <Helmet>
        <title>
          Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online -
          Kapoorlehengasaree
        </title>
        <meta
          name="description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta
          property="og:title"
          content="Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online - Kapoorlehengasaree"
        />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta property="og:url" content="https://kapoorlehengasaree.com/" />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta
          name="twitter:title"
          content="Buy women's Bridal Lehengas, Gowns, Sarees & Kurtis Online - Kapoorlehengasaree"
        />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="Check out the women's trendy collection of Bridal Lehengas, Gowns, designer Sarees & Kurtis sets Online at best prices from Kapoorlehengasaree. Don't wait Shop Now!"
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <>
            <div className="loader-overlay">
              <div className="loader-container">
                <div className="loader-circle-9">
                  Kapoor
                  <span></span>
                </div>
              </div>
            </div>
          </>
        }
      >
        <TopBar />
        <Header />
        <div className="max-w-[1836px] mx-auto">
          <HeroSlider
            bannerList={data?.AllBanners?.banner1}
            onClick={() => {
              navigate(
                `/product-page?category=${data?.AllBanners?.banner1.category_id}`
              );
            }}
          />
        </div>
        {/* Category section */}
        <section className="pt-[25px] pb-[30px]">
          <CategorySection data={data?.categorydata} />
        </section>

        {/* Watch and shop section */}
        <WatchShop data={data?.watch_and_shop} />

        {/* Third section */}
        <div className="my-[30px]">
          <div className="max-w-[1804px] mx-auto lg:px-3 overflow-hidden">
            <Link
              className="block h-[100px] lg:h-auto"
              to={`/product-page?category=${data?.AllBanners?.banner3?.[0]?.category_id}`}
            >
              <img
                src={data?.AllBanners?.banner3?.[0]?.file}
                className="w-full h-full object-cover  transition-transform duration-1000 ease-in-out transform hover:scale-105"
                alt=""
                loading="lazy"
              />
            </Link>
          </div>
        </div>

        {/* New arrival section */}
        <section className="my-[30px]">
          <div className="max-w-[1804px] mx-auto px-3">
            <div className="text-center mb-[30px]">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-normal mb-3">
                New Arrival
              </h2>
              <p className="xl:text-sm 2xl:text-lg !leading-none font-normal italic">
                &quot;Embrace the festival magic, let joy fill every
                moment.&quot;
              </p>
            </div>
            <div className="flex flex-wrap -mx-3 gap-y-[25px]">
              {Boolean(data?.newarrival) &&
                data?.newarrival?.map((item, index) => {
                  return (
                    <div
                      key={"newarrival-" + index}
                      className="new-arrival-card w-full xs:w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/5 2xl:w-1/5 px-3"
                    >
                      <NewArrivalCard info={item} />
                    </div>
                  );
                })}
              <div className="w-full text-center">
                <Link
                  className="2xl:text-lg font-medium px-4 xl:px-[33px] py-2.5 lg:py-[11px] bg-[#E9B159] text-white font-jost inline-flex items-center gap-2.5 3xl:gap-4 hover:bg-black hover:text-white"
                  to="/product-page"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* New on kapoor Section */}
        <section className="bg-[linear-gradient(to_bottom,#893e51_0%,#592935)] mt-[30px] mb-4 max-w-[1836px] mx-auto ">
          <div className="max-w-[1836px] mx-auto lg:px-3 py-[35px]">
            <div className="text-center mb-[35px]">
              <h2 className="text-2xl sm:text-3xl  md:text-3xl 2xl:text-4xl  !leading-none font-normal mb-3 text-white">
                New on Kapoor
              </h2>
              <p className="xl:text-sm 2xl:text-lg leading-none font-normal italic text-white">
                &quot;Find Everything For Your Every Need&quot;
              </p>
            </div>
            <NewOnKapoorSwiper info={data?.New_on_kapoor} />
          </div>
        </section>

        <section className="my-4 max-w-[1836px] mx-auto ">
          <div className="w-full px-[15px]">
            <div className="flex flex-wrap -mx-2 justify-center gap-y-3">
              <div className="w-full lg:w-[36%] px-2 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-2 gap-4">
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[0]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[1]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[2]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[3]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="w-full sm:w-5/12 lg:w-[28%] px-2">
                <div className="h-full relative overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[4]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                  />
                  <div className="absolute left-4 right-4 bottom-4 border-2 border-white backdrop-blur-[10px] bg-[rgba(0,0,0,0.2)] px-6 2xl:pl-[43px] 2xl:pr-[39px] pt-5 pb-[15px] rounded-[15px]">
                    <img
                      src="/images/logo.png"
                      className="h-8 md:h-auto mx-auto transition-transform duration-1000 ease-in-out transform hover:scale-105"
                      alt="logo"
                      width={200}
                      height={100}
                      loading="lazy"
                    />
                    <h3 className="sm:text-xl 2xl:text-2xl italic tracking-[0.48px] text-center text-white font-Rosarivo my-3 2xl:my-5">
                      Ever Bloom Ishya Purple Organza Jacquard Lehenga
                    </h3>
                    <ul className="flex items-center justify-center gap-[5px]">
                      <li className="w-5 xl:w-[30px] h-5 xl:h-[30px] grow-0 bg-[#ff3d3d] border-[3px] border-white rounded-full"></li>
                      <li className="w-5 xl:w-[30px] h-5 xl:h-[30px] grow-0 bg-[#59c0fb] border-[3px] border-white rounded-full"></li>
                      <li className="w-5 xl:w-[30px] h-5 xl:h-[30px] grow-0 bg-[#ffc147] border-[3px] border-white rounded-full"></li>
                      <li className="w-5 xl:w-[30px] h-5 xl:h-[30px] grow-0 bg-[#fa57e9] border-[3px] border-white rounded-full"></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[36%] px-2 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-2 gap-4">
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[5]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[6]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[7]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="overflow-hidden">
                  <img
                    src={data?.multiple_images?.files?.[8]}
                    className="h-full w-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="banner-section max-w-[1836px] mx-auto">
          <div className="banner-section-h">
            <div className="banner-section-content">
              <div className="border-solid border-2 rounded-[20px] p-2 w-[500px]">
                <div className="border-solid rounded-[20px] bg-white bg-opacity-70">
                  <div className="flex justify-center mb-5">
                    <img
                      src={bannerHomepage}
                      className="mx-auto"
                      width={200}
                      height={100}
                      loading="lazy"
                    />
                  </div>
                  <div className="lg:text-2xl xl:text-3xl font-bold flex justify-center italic p-6">
                    “Subscribe to our newsletter and get 10% off your first
                    purchase”
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-[53px] mt-[33px]">
          <div className="max-w-[1836px] mx-auto lg:px-3">
            <div className="text-center mb-[35px]">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl  !leading-none font-normal mb-3">
                New Launch Product
              </h2>
              <p className="xl:text-sm 2xl:text-lg !leading-none font-normal italic">
                &quot;Find Everything For Your Every Need&quot;
              </p>
            </div>
            <NewLaunchSwiper info={data?.new_lauch_products} />
          </div>
        </section>

        {/* Festival Special Section */}
        <section className="my-[35px]">
          <div className="max-w-[1804px] mx-auto px-3">
            <div className="text-center mb-[29px]">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-normal mb-3">
                Festival Special
              </h2>
              <p className="xl:text-sm 2xl:text-lg !leading-none font-normal italic">
                &quot;Embrace the festival magic, let joy fill every
                moment.&quot;
              </p>
            </div>
            <div className="flex flex-wrap -mx-3 gap-y-4">
              <div className="w-full md:w-6/12 px-3 overflow-hidden">
                <Link
                  to={`/product-page?category=${data?.AllBanners?.banner5?.[3]?.category_id}`}
                  className="h-full"
                >
                  <img
                    src={data?.AllBanners?.banner5?.[3]?.file}
                    className="h-full w-full transition-transform duration-1000 ease-in-out transform hover:scale-105"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
              <div className="w-full md:w-6/12 px-3">
                <div className="h-full flex flex-wrap -mx-3 gap-y-[28px]">
                  <div className="w-full px-3 overflow-hidden">
                    <Link
                      to={`/product-page?category=${data?.AllBanners?.banner5?.[0]?.category_id}`}
                      className="h-full"
                    >
                      <img
                        src={data?.AllBanners?.banner5?.[0]?.file}
                        className="h-full w-full transition-transform duration-1000 ease-in-out transform hover:scale-105"
                        alt=""
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div className="w-full sm:w-6/12 px-3 overflow-hidden">
                    <Link
                      to={`/product-page?category=${data?.AllBanners?.banner5?.[1]?.category_id}`}
                      className="h-full"
                    >
                      <img
                        src={data?.AllBanners?.banner5?.[1]?.file}
                        className="h-full w-full transition-transform duration-1000 ease-in-out transform hover:scale-105"
                        alt=""
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div className="w-full sm:w-6/12 px-3 overflow-hidden">
                    <Link
                      to={`/product-page?category=${data?.AllBanners?.banner5?.[2]?.category_id}`}
                      className="h-full"
                    >
                      <img
                        src={data?.AllBanners?.banner5?.[2]?.file}
                        className="h-full w-full transition-transform duration-1000 ease-in-out transform hover:scale-105"
                        alt=""
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* section */}
        <div className="my-[30px]">
          <div className="max-w-[1804px] mx-auto px-3 overflow-hidden">
            <Link
              className="block h-[200px]"
              to={`/product-page?category=${data?.AllBanners?.banner4?.[0]?.category_id}`}
            >
              <img
                src={data?.AllBanners?.banner4?.[0]?.file}
                className="w-full h-full object-cover transition-transform duration-1000 ease-in-out transform hover:scale-105"
                alt=""
                loading="lazy"
              />
            </Link>
          </div>
        </div>

        {/* shop by color section */}
        <section className="mt-[30px]">
          <div className="max-w-[1836px] mx-auto">
            <div className="text-center mb-[35px]">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-normal mb-3">
                Shop by Color
              </h2>
              <p className="xl:text-sm 2xl:text-lg !leading-none font-normal italic">
                &quot;Explore a vibrant world of style by color&quot;
              </p>
            </div>
            <div className="w-[100%] h-[30%]">
              <ShopByColor info={data?.colorist_product} />
            </div>
          </div>
        </section>

        {/* CustomerStories section */}
        <section className="bg-[#ffdb7f17] max-w-[1836px] mx-auto">
          <h2 className="text-[26px] leading-none font-normal font-jost text-center bg-[#ffd37f75] py-2.5">
            Customer Stories
          </h2>
          <div className="max-w-[1604px] mx-auto px-[15px] pb-[33px]">
            <div className="flex flex-wrap -mx-[15px] pt-20 gap-y-12 lg:gap-y-20 justify-center">
              {data?.testimonials?.map((item, index) => {
                return (
                  <div
                    key={"testimonial-" + index}
                    className="w-full sm:w-6/12 xl:w-2/6 px-[15px]"
                  >
                    <CustomerStories info={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* section */}
        <section className="my-[3px]">
          <div className="max-w-[1464px] mx-auto px-3">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full sm:w-6/12 xl:w-1/4 py-5 md:py-10 px-6 text-center">
                <div className="max-h-[50px] mx-auto mb-2.5">
                  <img
                    src="/images/delivery-1.png"
                    className="mx-auto"
                    alt="delivery-1"
                    loading="lazy"
                    width={50}
                    height={50}
                  />
                </div>
                <h3 className="text-[15px] font-bold font-jost uppercase">
                  CASH ON DELIVERY
                </h3>
                <p className="text-[15px] leading-5 font-normal font-jost">
                  Pay on Delivery available in India upto 3000 INR
                </p>
              </div>
              <div className="w-full sm:w-6/12 xl:w-1/4 py-5 md:py-10 px-6 text-center">
                <div className="max-h-[50px] mx-auto mb-2.5">
                  <img
                    src="/images/exchange.png"
                    className="mx-auto"
                    alt="exchange"
                    loading="lazy"
                    width={50}
                    height={50}
                  />
                </div>
                <h3 className="text-[15px] font-bold font-jost uppercase">
                  SIMPLE EXCHANGE
                </h3>
                <p className="text-[15px] leading-5 font-normal font-jost">
                  You can Easily exchange any products within 02 days.
                </p>
              </div>
              <div className="w-full sm:w-6/12 xl:w-1/4 py-5 md:py-10 px-6 text-center">
                <div className="max-h-[50px] mx-auto mb-2.5">
                  <img
                    src="/images/stitching.png"
                    className="mx-auto"
                    alt="stitching"
                    loading="lazy"
                    width={50}
                    height={50}
                  />
                </div>
                <h3 className="text-[15px] font-bold font-jost uppercase">
                  stitching services
                </h3>
                <p className="text-[15px] leading-5 font-normal font-jost">
                  We are Provide Stitching Services for all Outfit&apos;s.
                </p>
              </div>
              <div className="w-full sm:w-6/12 xl:w-1/4 py-5 md:py-10 px-6 text-center">
                <div className="max-h-[50px] mx-auto mb-2.5">
                  <img
                    src="/images/world-wide.png"
                    className="mx-auto"
                    alt="world-wide"
                    loading="lazy"
                    width={50}
                    height={50}
                  />
                </div>
                <h3 className="text-[15px] font-bold font-jost uppercase">
                  WORLDWIDE SHIPPING
                </h3>
                <p className="text-[15px] leading-5 font-normal font-jost">
                  We are Delivered Product to Worldwide.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* OUR STORES section */}
        <div className="relative w-full h-[90px] md:h-[255px] flex justify-center items-center max-w-[1836px] mx-auto">
          <div className="absolute inset-0 w-full h-full overflow-hidden">
            <img
              src={data?.our_story?.[0]}
              alt="Our Story"
              className="object-cover w-full h-full transition-transform duration-1000 ease-in-out transform hover:scale-105"
            />
          </div>
          <Link
            className="relative z-10 inline-block xl:max-w-[300px] xl:w-full text-center px-8 py-2 md:py-4 xl:py-4 text-xl md:text-2xl xl:text-3xl md:leading-[50px] font-medium font-jost bg-white"
            to="/our-store"
          >
            OUR STORES
          </Link>
        </div>

        {/* section */}
        <section className="py-8 bg-[#E3FCFF] mb-[50px] max-w-[1836px] mx-auto">
          <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl font-jost font-medium leading-[50px] text-black text-center mb-4">
            We Serve 56 Country
          </h2>
          <div>
            <img
              src={data?.map_image?.[0] || "/images/world-map.png"}
              className="mx-auto w-[100%]"
              width={1000}
              alt="world-map"
              loading="lazy"
            />
          </div>
        </section>

        <section className="mt-[23px] mb-[30px]">
          <div className="max-w-[1804px] mx-auto px-3 text-center">
            <h2 className="text-xl leading-none font-bold font-jost from-black mb-4 md:mb-[30px]">
              POPULAR SEARCHES
            </h2>
            <div className="flex flex-wrap justify-center gap-x-1">
              {data?.POPULAR_SEARCH?.map((item, index) => {
                return (
                  <Link
                    to="/product-page"
                    className="text-[13px] leading-[17px] font-normal font-jost text-black"
                    // key={"popularSearch" + index}
                  >
                    {item} |
                  </Link>
                );
              })}
            </div>
          </div>
        </section>

        <section className="my-10 md:mt-[50px] md:mb-[60px] ">
          <div className="max-w-[1600px] mx-auto px-3">
            <div className="text-center mb-5 md:mb-8 xl:mb-14">
              <h2 className="text-xl md:text-2xl xl:text-[35px] !leading-none font-normal font-jost pb-5 xl:pb-9 border-b border-black inline-block ">
                {data?.DISCRIPTION?.[0]?.main_title}
              </h2>
            </div>

            {data?.DISCRIPTION?.map((item, index) => {
              if (index !== 0) {
                return (
                  <div key={"details" + index}>
                    <h4 className="text-lg md:text-2xl leading-5 md:leading-7 font-normal font-jost text-black mb-4 md:mb-5">
                      {item?.title}
                    </h4>
                    <p className="text-[15px] leading-5 font-normal font-Roboto text-[#7A7A7A] mb-3 md:mb-4 xl:mb-[22px]">
                      {item?.description}
                    </p>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </section>
        <Footer />
      </Suspense>
    </>
  );
};

export default HomePage;
