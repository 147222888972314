import React, { useContext, useRef, useState } from "react";
import { CouponIcon } from "../assets/SvgIcons";
import { Box, Drawer } from "@mui/material";
import { IoIosClose, IoMdRadioButtonOff } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa6";
import BagCard from "./bag/BagCard";
import { MdShoppingCart } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { LoginDrawerContext } from "../context/LoginDrawerContext";
import { CartContext } from "../context/CartContext";
import facebookImg from "../assets/images/facebook.png";
const Footer = () => {
  // ------------------------- this is header form start ------------------------------
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setErrorEmail("Please enter a valid email address.");
      return;
    }

    setErrorEmail("");
    setIsSubmitting(true);
    setMessage("");

    try {
      const { data } = await axios.post("/savenewsletter", {
        news_letter_email: email,
      });

      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "OTP send.");
        setEmail("");
        setMessage("Thank you for subscribing!");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // ------------------------- this is header form end ------------------------------

  // ------------------------- this is footer cart drawer start ------------------------------

  const { showLogin, setShowLogin } = useContext(LoginDrawerContext);
  const navigate = useNavigate();
  const [showBag, setShowBag] = useState(false);
  const priceSummaryRef = useRef(null);

  const toggleDrawer = (newOpen) => () => {
    setShowBag(newOpen);
  };

  const { cartInfo, setCartInfo, cartIcons, setCartIcons, fetchCartItems } =
    useContext(CartContext);

  const handleViewDetails = () => {
    if (priceSummaryRef.current) {
      priceSummaryRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // ------------------------- this is footer cart drawer start ------------------------------

  return (
    <>
      <div className="bg-[#3E3E3E] py-6 max-w-[1836px] mx-auto">
        <div className="max-w-[1600px] mx-auto flex flex-col sm:flex-row justify-around gap-y-5">
          <div className="px-3">
            <h3 className="text-sm leading-5 font-semibold tracking-[0.1px] text-white font-Inter mb-3">
              Download our App
            </h3>
            <div className="flex items-center gap-3 md:gap-5">
              <Link to="https://play.google.com/store/apps/" target="_blank">
                <img
                  src="/images/google-play.png"
                  alt="google-play"
                  width={100}
                  height={60}
                  loading="lazy"
                />
              </Link>
              <Link
                to="https://apps.apple.com/in/app/kapoor-lehenga/id6499420102"
                target="_blank"
              >
                <img
                  src="/images/apple-app.png"
                  alt=""
                  width={100}
                  height={60}
                  loading="lazy"
                />
              </Link>
            </div>
          </div>
          <div className="px-3">
            <h3 className="text-sm leading-5 font-semibold tracking-[0.1px] text-white font-Inter mb-3">
              FOR ANY HELP, YOU MAY CALL US AT
            </h3>
            <h3 className="text-xs leading-4 font-semibold tracking-[0.6px] text-white font-Inter">
              7409555455
            </h3>
            <p className="text-xs leading-4 font-normal text-white font-Inter">
              (Monday to Saturday: 11 am - 9 pm, Sunday: 12 am - 7 pm)
            </p>
          </div>
        </div>
      </div>

      <div className="py-10 ">
        <div className="max-w-[1600px] mx-auto px-3">
          <div className="flex flex-wrap -mx-3 gap-y-4">
            <div className="w-full sm:w-6/12 md:w-3/12 lg:w-1/5 px-3">
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                Company
              </h3>
              <ul>
                <li className="">
                  <Link
                    to="/privancypolicy"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/contact-us"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/about-us"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to="/term-and-condition"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Terms & conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/return-cancellation"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Refunds/Cancellations
                  </Link>
                </li>
                <li>
                  <Link
                    to="/delete-account"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Delete Account
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-6/12 md:w-3/12 lg:w-1/5 px-3">
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                Information
              </h3>
              <ul>
                <li>
                  <Link
                    to={"/blog"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Blog/Articles
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/subscribe-new-letter"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Subscribe to Newsletter
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/affiliate-program"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Affiliate Program
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/faqs"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    FAQ&apos;s
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/franchise"}
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Franchise
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full sm:w-6/12 md:w-3/12 lg:w-1/5 px-3">
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                MY ACCOUNT
              </h3>
              <ul>
                <li>
                  <button
                    className="animated-list-item text-[13px] leading-[22px] "
                    onClick={() => {
                      setShowLogin(true);
                    }}
                  >
                    Login
                  </button>
                </li>
                <li>
                  <button
                    className="animated-list-item text-[13px] leading-[22px] "
                    onClick={toggleDrawer(true)}
                  >
                    Shopping Bag
                  </button>
                </li>
                <li>
                  <Link
                    to="/wishlist"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Wishlist
                  </Link>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="w-full sm:w-6/12 md:w-3/12 lg:w-[15%] px-3">
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                Social
              </h3>
              <ul>
                <li>
                  <Link
                    to="https://www.facebook.com/KAPOORLEHENGASAREE"
                    target="_blank"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Facebook
                  </Link>
                </li>

                <li>
                  <Link
                    to="https://www.instagram.com/kapoorlehengasaree/?hl=en"
                    target="_blank"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    Instagram
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.youtube.com/channel/UCoybOj2H8uV--DpKcaumqlw"
                    target="_blank"
                    className="animated-list-item text-[13px] leading-[22px] "
                  >
                    YouTube
                  </Link>
                </li>
                <li></li>
              </ul>
            </div>
            <div className="w-full md:w-8/12 lg:w-3/12 px-3">
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                SAFE & SECURE PAYMENT
              </h3>
              <ul className="flex flex-wrap items-center gap-y-1 gap-x-0.5 mb-5">
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/visa.png" alt="" loading="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/rupay.png" alt="" loading="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/upi.png" alt="" loading="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/amazon.png" alt="" loading="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/paypal.png" alt="" loading="lazy" />
                  </Link>
                </li>
                <li>
                  <Link to="/" className="block text-[13px] leading-[22px] ">
                    <img src="/images/paytm.png" alt="" loading="lazy" />
                  </Link>
                </li>
              </ul>
              <h3 className="text-lg leading-5 font-normal font-Roboto mb-3.5 sm:mb-5">
                STAY IN TOUCH
              </h3>
              <form onSubmit={handleEmailSubmit} className="space-y-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Type your email here"
                  className="max-w-[314px] w-full text-sm text-white placeholder:text-white font-Roboto bg-black px-3 py-2.5 mb-2"
                  // required
                />
                {errorEmail && <p className="text-red-500">{errorEmail}</p>}
                {message && <p className="text-red-500">{message}</p>}
                <button
                  type="submit"
                  className="text-[13px] leading-5 font-Roboto text-black bg-[#E9B159] py-2.5 px-5"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Subscribing..." : "Subscribe"}
                </button>
                {message && <p>{message}</p>}
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1836px] mx-auto">
        <img
          src="/images/footer-elem.png"
          className="w-full"
          alt=""
          loading="lazy"
        />
      </div>

      <Drawer open={showBag} onClose={toggleDrawer(false)} anchor="right">
        <Box
          role="presentation"
          className="max-w-[300px] lg:!max-w-[450px] w-screen"
        >
          <div className="w-screen max-w-[300px] lg:max-w-[450px] h-full bg-white absolute top-0 right-0 p-3 lg:p-5">
            <div className="bag-header">
              <button
                className="w-10 aspect-square flex items-center justify-center"
                onClick={() => {
                  setShowBag(false);
                }}
              >
                <IoIosClose size={40} />
              </button>
              <h4 className="my-3 2xl:my-[30px] text-xl lg:text-2xl 2xl:text-[40px] font-medium !leading-none">
                Bag
              </h4>
            </div>
            {cartInfo?.cartcount ? (
              <>
                <div className="h-[calc(100%-157px)] lg:h-[calc(100%-192px)] 2xl:h-[calc(100%-244px)] overflow-auto mb-6 scrollbar">
                  <div className="grid gap-[15px] mb-6">
                    {cartInfo?.cartdata?.map((item, index) => {
                      // console.log("cartInfo:::::",item)
                      return (
                        <BagCard
                          key={"cartItem" + item?.id}
                          info={item}
                          cartId={cartInfo?.cart_id}
                          fetchCartItems={fetchCartItems}
                        />
                      );
                    })}
                  </div>
                  <Link to={"#"}>
                    <div className="border border-[#CBCBCB] bg-[#F8F8F8] p-2 lg:px-4 lg:py-3 flex items-center justify-between gap-[14px] rounded-[5px]">
                      <div className="flex items-center gap-[14px]">
                        <div>
                          <CouponIcon />
                        </div>
                        <div>
                          <p className="text-base xl:text-[22px] font-medium !leading-none  text-black lg:mb-2">
                            Coupons
                          </p>
                          <span className="text-[#555555] text-sm lg:text-lg font-normal !leading-none ">
                            Apply Coupons and save extra
                          </span>
                        </div>
                      </div>
                      <div>
                        <FaAngleRight size={26} />
                      </div>
                    </div>
                  </Link>
                  <div
                    className="border border-[#CBCBCB] bg-[#F8F8F8] px-3 lg:px-4 py-3 lg:py-5 rounded-[10px] mt-3 lg:mt-6"
                    ref={priceSummaryRef}
                  >
                    <div>
                      <h5 className="text-xl md:text-2xl mb-3 font-medium !leading-none ">
                        Price Summary
                      </h5>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        Bag Total
                      </p>
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        ₹{cartInfo?.total_gross_amount || 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        Discount on MRP
                      </p>
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        ₹{cartInfo?.total_discount_amount || 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        Sub Total
                      </p>
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        ₹{cartInfo?.before_gst || 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        GST {cartInfo?.gst_percentage}%
                      </p>
                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                        ₹{cartInfo?.gst_amount || 0}
                      </p>
                    </div>
                    <div className="flex items-center justify-between">
                      <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                        You Pay
                      </p>
                      <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                        ₹{cartInfo?.total_net_amount || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3 justify-between">
                  <div>
                    <h5 className="text-lg font-medium leading-8 ">
                      ₹{cartInfo?.total_net_amount || 0}
                    </h5>
                    <button
                      onClick={handleViewDetails}
                      className="text-base font-normal leading-5  text-[#FF2727]"
                    >
                      View Details
                    </button>
                  </div>
                  <Link
                    to={"/choose-address"}
                    onClick={() => setShowBag(false)}
                    className="bg-[#E9B159] p-3 lg:p-7 max-w-[360px] sm:w-[240px] lg:w-[360px] text-lg lg:text-2xl font-medium !leading-none text-center text-white"
                  >
                    Proceed to Buy
                  </Link>
                </div>
              </>
            ) : (
              <div className="p-4 flex items-center flex-col justify-center">
                <MdShoppingCart className="text-7xl my-4" />
                <p>Your cart is empty !</p>
                <button
                  className="mt-5 bg-black text-white rounded px-4 py-2"
                  onClick={() => {
                    setShowBag(false);
                    navigate("/product-page");
                  }}
                >
                  Continue Shopping
                </button>
              </div>
            )}
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default Footer;
