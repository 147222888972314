import React, { useEffect, useState } from "react";
import Faq from "../components/Faq";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { Helmet } from "react-helmet";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import toast from "react-hot-toast";

const FaqsPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost capitalize"
    >
      FAQs
    </p>,
  ];

  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFaqs = async () => {
    try {
      const { data } = await axios.get("Faqs");
      if (data && data?.STATUS === 200) {
        setFaqData(data?.DATA || []);
        console.log("shajshja", data?.DATA);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE || err?.message || "Failed to fetch faqs."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <>
      <Helmet>
        <title>FAQs - Kapoorlehengasaree</title>
        <meta name="description" content="FAQs" />
        <meta property="og:title" content="FAQs" />
        <meta property="og:type" content="website" />
        <meta name="description" property="og:description" content="FAQs" />
        <meta property="og:url" content="https://kapoorlehengasaree.com/faqs" />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="FAQs" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta name="twitter:description" content="FAQs" />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/faqs" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <section className="my-8">
        <div className="max-w-[1474px] mx-auto px-3">
          <h3 className="text-xl md:text-2xl md:leading-5 font-medium font-jost text-start mb-6">
            FAQ&apos;s
          </h3>
          <Faq faqData={faqData} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FaqsPage;
