import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { InputBase, TextareaAutosize } from "@mui/material";
import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import axios from "axios";
import { STORAGE } from "../config/config";
import { Helmet } from "react-helmet";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const FranchisePage = () => {
  const navigate = useNavigate();

  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Franchise
    </p>,
  ];

  const schema = yup.object().shape({
    business_name: yup.string().required("Business name is required."),
    business_owner: yup.string().required("Owner name is required."),
    contact_number: yup
      .string()
      .required("contact number is required.")
      .length(10, "Invalid contact number."),
    email: yup.string().email().required("Email is required."),
    address: yup.string().required("Address is required."),
    type_of_bussiness: yup.string().required("Business type is required."),
    bussiness_plane: yup.string().required("Business plane is required."),
    description: yup.string().required("Description is required."),
  });

  const defaultValues = {
    business_name: null,
    business_owner: null,
    contact_number: null,
    email: null,
    address: null,
    type_of_bussiness: null,
    bussiness_plane: null,
    description: null,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    getFieldState,
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const saveFranchiseForm = async (payload) => {
    const id = toast.loading("saving...");
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      let obj = {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        is_admin: "0",
        user_id: userProfile?.id,
        ...payload,
      };
      const { data } = await axios.post("franchise", obj);
      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "Franchise Request Sent successfully.");
        navigate("/");
      }
      console.log(data);
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE ||
          err?.message ||
          "Failed to store information."
      );
    } finally {
      toast.dismiss(id);
    }
  };
  const onSubmit = (data) => {
    saveFranchiseForm(data);
  };

  return (
    <>
      <Helmet>
        <title>Franchise - Kapoorlehengasaree</title>
        <meta property="og:title" content="Franchise" />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="Franchise"
        />
        <meta
          property="og:url"
          content="https://kapoorlehengasaree.com/franchise"
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="Franchise" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta name="twitter:description" content="Franchise" />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://kapoorlehengasaree.com/franchise" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>

      <TopBar />
      <Header />

      <Breadcrumb list={breadcrumbArray} />

      <div className="relative max-w-[1836px] mx-auto">
        <img
          src="/images/franchise-img.png"
          className="w-full "
          alt=""
          loading="lazy"
        />
      </div>
      <div className="flex justify-center items-center my-5">
        <img
          src="/images/logo-gold.png"
          className="w-[200px]"
          alt=""
          loading="lazy"
        />
      </div>
      <div className="flex justify-center items-center mb-4">
        <div className="text-sm lg:text-xl w-[53%] text-center">
          Kapoor lehenga & saree in a short span has become a fashion force to
          recon with. Join the force and be a part of its flexible franchise
          model.
        </div>
      </div>
      <div className="flex justify-center items-center mb-5">
        <div className="text-sm lg:text-xl w-[53%] text-center">
          Kapoor lehenga & saree offers both Franchise Owned Company Operated
          (FOCO) model and Franchise Operated and Franchise Owned (FOFO) model
        </div>
      </div>
      <section className="bg-[#F2F2F2] max-w-[1836px] mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost text-center py-5">
          WE HAVE YOU COVERED
        </h2>
        <div className="max-w-[1100px] mx-auto pb-[33px]">
          <div className="flex flex-wrap gap-y-5 lg:gap-y-5 justify-center">
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-start.png"
                    className="w-[70px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Getting Started</div>
                  <div>
                    Help in location identification, store design and setup
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-management.png"
                    className="w-[100px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Management</div>
                  <div>
                    Operate stores for FOCO operated model and provide with pre
                    approved operating procedure for the FOFO model
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-support.png"
                    className="w-[70px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Training and support</div>
                  <div>In-depth employee training and support</div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-project.png"
                    className="w-[100px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Technology-enabled</div>
                  <div>
                    Artificial intelligence-based (AI) prediction engine for
                    accurate, just-in-time stock replenishment and rapid
                    ordering
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-shopping.png"
                    className="w-[100px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Stock and merchandise</div>
                  <div>
                    Identification of trends, 100% stock correction and
                    freshness via AI-enabled system and expert guidance
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-6/12 xl:w-6/12 px-[15px]">
              <div className="flex gap-3 h-full bg-white rounded-[10px] p-4">
                <div className="flex justify-center items-center">
                  <img
                    src="/images/franchise-img-email.png"
                    className="w-[100px]"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="grid items-center">
                  <div className="font-bold">Marketing support</div>
                  <div>
                    National and regional marketing support to attract and
                    engage customers using owned, earned, and paid media
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-repeat-x bg-[top_center] w-full py-8 md:py-14">
        <div className="max-w-[1418px] mx-auto px-3">
          <div className="bg-white px-5 md:px-8 xl:pl-[60px] xl:pr-[75px] py-[30px]">
            <div className="w-full bg-white mb-6 lg:mb-10">
              <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost text-center">
                PROVIDE YOUR DETAILS
              </h2>
            </div>
            <div className="w-full">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-5 gap-x-5 ">
                  <div className="w-full">
                    <Controller
                      name="business_owner"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field?.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Business Owner Name"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Address"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="business_name"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Business Name"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="type_of_bussiness"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Type of Business"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="contact_number"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Contact Number"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              type="number"
                              inputProps={{ maxLength: 10 }}
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="bussiness_plane"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Business Plane"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Email Id"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full ">
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => {
                        const errorMessage = getFieldState(field.name)?.error
                          ?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Description"
                              className="h-12 w-full !text-lg sm:!text-xl !leading-none font-jost bg-[#F5F5F5] px-4 py-3 xl:p-6  text-black !placeholder:text-[#464646] border-b border-[#CDCDCD]"
                              {...field}
                            />
                            <p className="text-red-500">{errorMessage}</p>
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-center mt-10 xl:mt-20">
                  <button
                    variant="contained"
                    className="w-full max-w-[532px] h-12 bg-[#E9B159] hover:bg-[#E9B159] text-white text-xl font-jost uppercase font-bold"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FranchisePage;
