"use client";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { InputBase, TextareaAutosize, Tab, Tabs } from "@mui/material";
import { LocationIcon, CallingIcon, TimeIcon } from "../assets/SvgIcons";
import toast from "react-hot-toast";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { STORAGE } from "../config/config";
import { Helmet } from "react-helmet";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { MdOutlineMail } from "react-icons/md";

const ContactUsPage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      Contact Us
    </p>,
  ];

  const schema = yup.object().shape({
    inquiry_sender_name: yup.string().required("Name is required"),
    inquiry_sender_mobile: yup
      .string()
      .required("Contact number is required")
      .length(10, "Invalid Contact Number."),
    inquiry_sender_email: yup.string().email().required("Email is required"),
    inquiry_message: yup.string().required("Message is required"),
  });

  const {
    control,
    formState: { errors },
    getFieldState,
    handleSubmit,
  } = useForm({
    defaultValues: {
      inquiry_sender_name: "",
      inquiry_sender_mobile: "",
      inquiry_sender_email: "",
      inquiry_message: "",
    },
    resolver: yupResolver(schema),
  });

  const [activeTab, setActiveTab] = useState(0);
  const [contactInfo, setContactInfo] = useState({});
  console.log("Map----->", contactInfo?.map_url);
  const [loading, setLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchContactUsInformation = async (tabIndex = 0) => {
    try {
      let type = "";
      switch (tabIndex) {
        case 0:
          type = "1";
          break;
        case 1:
          type = "2";
          break;
        case 2:
          type = "3";
          break;
        default:
          break;
      }
      setLoading(true);
      const { data } = await axios.post("contact_us", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        type: type || "1",
      });
      if (data && data?.STATUS === 200) {
        setContactInfo(data?.DATA);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE || "Failed to fetch contact information."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactUsInformation(activeTab);
  }, [activeTab]);

  useEffect(() => {
    console.log("Contact Info:", contactInfo);
  }, [contactInfo]);

  const handleSubmitContactUs = async (payload) => {
    const id = toast.loading("Sending...");
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.post("saveproductinquiry", {
        device_id: localStorage.getItem(STORAGE?.DEVICEID),
        user_id: userProfile?.id,
        user_type: userProfile?.user_type ?? STORAGE?.B2C,
        ...payload,
      });
      console.log(data);
      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "Inquiry Sent successfully.");
      }
    } catch (err) {
      console.error(err);
      err?.response
        ? toast.error(err?.response?.data?.MESSAGE || "Failed to send message.")
        : toast.error(err.message || "Failed to send message.");
    } finally {
      toast.dismiss(id);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    handleSubmitContactUs(data);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Kapoorlehengasaree</title>
        <meta property="og:title" content="Contact Us" />
        <meta property="og:type" content="website" />
        <meta
          name="description"
          property="og:description"
          content="You can fill out the form below or contact us at 805-800-5803 if you have any questions or need general information. We will respond soon"
        />
        <meta
          property="og:url"
          content="https://kapoorlehengasaree.com/contact-us"
        />
        <meta
          name="image"
          property="og:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />

        <meta name="twitter:title" content="Contact Us" />
        <meta property="twitter:site" content="@Kapoorlehengasaree" />
        <meta
          name="twitter:description"
          content="You can fill out the form below or contact us at 805-800-5803 if you have any questions or need general information. We will respond soon"
        />
        <meta
          name="twitter:image"
          content="https://kapoorlehengasaree.com/images/header-logo.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href="https://kapoorlehengasaree.com/contact-us"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="X-Robots-Tag" content="index, follow" />
        <meta
          name="keywords"
          content="Bridal Lehengas, Gowns, Sarees, Kurtis, Women's Fashion, Online Shopping, Designer Lehengas, Traditional Wear, Indian Fashion"
        />

        <meta name="publisher" content="Kapoorlehengasaree" />
      </Helmet>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div
        className="bg-repeat-x bg-[top_center] w-full pt-8 md:pt-14 max-w-[1836px] mx-auto"
        style={{ backgroundImage: "url(/images/contact-us-bg.png)" }}
      >
        <div className="max-w-[1418px] mx-auto px-3">
          <div className="w-full">
            <div className="w-full">
              <Tabs
                className="contact-us-tab bg-white"
                value={activeTab}
                onChange={handleTabChange}
                sx={{ "& .MuiTabs-indicator": { display: "none" } }}
              >
                <Tab
                  label="Head office"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "white",
                      borderBottom: "none !important",
                    },
                    flex: "1 0 33.33%",
                    maxWidth: "33.33%",
                  }}
                  className={
                    activeTab
                      ? "!h-[70px] !max-w-full flex-1 !font-medium !border-b-0 !text-xs sm:!text-xl lg:!text-[15px] !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                      : "!h-[70px] !max-w-full flex-1 !text-xs sm:!text-xl lg:!text-[15px] font-medium !border-0 !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                  }
                />
                <Tab
                  label="Branch 1"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "white",
                      borderBottom: "none !important",
                    },
                    flex: "1 0 33.33%",
                    maxWidth: "33.33%",
                  }}
                  className={
                    activeTab
                      ? "!h-[70px] !max-w-full flex-1 !font-medium !border-b-0 !text-xs sm:!text-xl lg:!text-[15px] !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                      : "!h-[70px] !max-w-full flex-1 !text-xs sm:!text-xl lg:!text-[15px] font-medium !border-0 !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                  }
                />
                <Tab
                  label="Branch 1"
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "black",
                      color: "white",
                      borderBottom: "none !important",
                    },
                    flex: "1 0 33.33%",
                    maxWidth: "33.33%",
                  }}
                  className={
                    activeTab
                      ? "!h-[70px] !max-w-full flex-1 !font-medium !border-b-0 !text-xs sm:!text-xl lg:!text-[15px] !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                      : "!h-[70px] !max-w-full flex-1 !text-xs sm:!text-xl lg:!text-[15px] font-medium !border-0 !leading-[1.54] !px-1 !py-2.5 sm:!p-[15px]"
                  }
                />
              </Tabs>
            </div>
            <div>
              <div className="w-full flex flex-wrap border-2 border-[#ECEAEA]">
                <div className="md:w-[53.3%] px-3 lg:px-10 xl:pl-[70px] xl:pr-[50px] py-5 md:pt-[30px] md:pb-[38px] bg-white">
                  <h3 className="text-xl md:text-2xl 2xl:text-2xl font-medium !leading-none text-black mb-3 md:mb-4 xl:mb-9">
                    Get in touch
                  </h3>
                  <div className="w-full border bg-[#f4f4f4] p-2 rounded-[10px] flex items-center gap-2 md:gap-[18px] mb-5">
                    <div className="flex-shrink-0">
                      <LocationIcon />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg sm:text-xl  font-medium !leading-[1.22] text-black mb-3">
                        Address
                      </h3>
                      <p className="text-md font-normal !leading-[1.22] text-[#464646]">
                        {contactInfo?.address}
                      </p>
                    </div>
                  </div>
                  <div className="w-full border bg-[#f4f4f4] p-2 rounded-[10px] flex items-center gap-2 md:gap-[18px] mb-5">
                    <div className="flex-shrink-0">
                      <MdOutlineMail size={40} />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg sm:text-xl font-medium !leading-[1.22] text-black mb-3">
                        Email
                      </h3>
                      <p className="text-md font-normal !leading-[1.22] text-[#464646]">
                        {contactInfo?.email}
                      </p>
                    </div>
                  </div>
                  <div className="w-full border bg-[#f4f4f4] p-2 rounded-[10px] flex items-center gap-2 md:gap-[18px] mb-5">
                    <div className="flex-shrink-0">
                      <CallingIcon />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg sm:text-xl font-medium !leading-[1.22] text-black mb-3">
                        WhatsApp/Call Us
                      </h3>
                      <p className="text-md font-normal !leading-[1.22] text-[#464646]">
                        {contactInfo?.mo_number}
                      </p>
                    </div>
                  </div>
                  <div className="w-full border bg-[#f4f4f4] p-2 rounded-[10px]  flex items-center gap-2 md:gap-[18px] mb-5">
                    <div className="flex-shrink-0">
                      <TimeIcon />
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg sm:text-xl font-medium !leading-[1.22] text-black mb-3">
                        Open:
                      </h3>
                      <p className="text-md font-normal !leading-[1.22] text-[#464646]">
                        {contactInfo?.open}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex-1 md:w-[46.7%] px-3 lg:px-10 xl:pr-[70px] xl:pl-[50px] py-5 md:pt-[30px] md:pb-[38px] bg-[#ECEAEA]">
                  <h3 className="text-xl lg:text-2xl xl:text-2xl font-medium !leading-none text-black mb-3 md:mb-4 xl:mb-9">
                    Contact Us
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid gap-y-5 lg:gap-y-[35px]">
                      <div className="w-full ">
                        <Controller
                          name="inquiry_sender_name"
                          control={control}
                          render={({ field }) => {
                            const errorMessage = getFieldState(field.name)
                              ?.error?.message;
                            return (
                              <>
                                <InputBase
                                  {...field}
                                  classes={{ input: "py-0" }}
                                  placeholder="Full Name"
                                  className="h-12 w-full !text-sm !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black placeholder:text-[#858585] "
                                  error={!!errorMessage}
                                />
                                <p className="text-red-500">{errorMessage}</p>
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="w-full ">
                        <Controller
                          name="inquiry_sender_mobile"
                          control={control}
                          render={({ field }) => {
                            const errorMessage = getFieldState(field.name)
                              ?.error?.message;

                            return (
                              <>
                                <InputBase
                                  classes={{ input: "py-0" }}
                                  placeholder="Contact Number"
                                  className="h-12 w-full !text-sm !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black placeholder:text-[#858585] "
                                  type="number"
                                  inputProps={{ maxLength: 10 }}
                                  {...field}
                                  error={!!errorMessage}
                                  // required
                                />
                                <p className="text-red-500">{errorMessage}</p>
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="w-full ">
                        <Controller
                          name="inquiry_sender_email"
                          control={control}
                          render={({ field }) => {
                            const errorMessage = getFieldState(field.name)
                              ?.error?.message;
                            return (
                              <>
                                <InputBase
                                  {...field}
                                  classes={{ input: "py-0" }}
                                  placeholder="Email Id"
                                  className="h-12 w-full !text-sm !leading-none font-jost bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black placeholder:text-[#858585] "
                                  error={!!errorMessage}
                                />
                                <p className="text-red-500">{errorMessage}</p>
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="w-full ">
                        <Controller
                          name="inquiry_message"
                          control={control}
                          render={({ field }) => {
                            const errorMessage = getFieldState(field.name)
                              ?.error?.message;
                            return (
                              <>
                                <TextareaAutosize
                                  {...field}
                                  placeholder="Massage..."
                                  sx={{
                                    "&::placeholder": { color: "#858585" },
                                  }}
                                  className="scrollbar-none w-full text-sm !leading-none font-jost font-normal bg-white px-4 py-3 xl:p-6 rounded-md sm:rounded-[10px] text-black placeholder:text-[#858585] !h-[150px] outline-0 resize-none !overflow-y-auto"
                                />
                                <p className="text-red-500">{errorMessage}</p>
                              </>
                            );
                          }}
                        />
                      </div>
                      <div className="w-full ">
                        <button
                          type="submit"
                          variant="contained"
                          className="w-full h-12 bg-[#E9B159] hover:bg-[#E9B159] text-white text-xl font-bold font-jost rounded-md sm:rounded-[10px] px-6 py-3"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-cover bg-no-repeat w-full my-10 md:my-16 text-center py-10 px-5"
            style={{ backgroundImage: "url(/images/shope-via-call-bg.png)" }}
          >
            <h3 className="text-xl lg:text-2xl xl:text-2xl  font-medium !leading-none text-black mb-3 md:mb-5">
              Shop via video call
            </h3>
            <p className="sm:text-sm md:text-md !leading-none font-jost font-normal text-black mb-6">
              Interact with our fashion consultants, and enjoy personalised
              shopping experience
            </p>
            <Link
              to="/video-appointment"
              className="inline-block text-center px-10 sm:px-14 xl:px-[156px] py-4 xl:py-[10px] sm:text-xl md:text-2xl xl:text-[28px] !leading-none font-medium font-jost bg-white"
            >
              BOOK NOW
            </Link>
          </div>
          <div
            className="h-96 lg:h-[634px] mb-10 lg:mb-[70px] w-full"
            dangerouslySetInnerHTML={{
              __html: contactInfo?.map_url,
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUsPage;
