import React, { useState } from "react";
import ProductQtyCounter from "./ProductQtyCounter";
import { FaXmark } from "react-icons/fa6";
import toast from "react-hot-toast";
import axios from "axios";
import { debounce } from "lodash";
import { STORAGE } from "../../config/config";
import { Link, useNavigate } from "react-router-dom";

const BagCard = ({ info, cartId, fetchCartItems }) => {
  const [itemInfo, setItemInfo] = useState(info || {});
  const navigate = useNavigate();

  const updateCartItem = async (cartChildId, quantity) => {
    toast.dismiss();
    const toastId = toast.loading("Updating cart...");
    try {
      const { data } = await axios.post("updatecart", {
        cart_child_id: cartChildId,
        product_quantity: quantity,
      });
      if (data && data?.STATUS === 200) {
        // toast.success(data?.MESSAGE || "Cart updated.");
      }
      await fetchCartItems();
    } catch (err) {
      toast.error(err?.response?.data?.MESSAGE || "Something went wrong");
    } finally {
      toast.dismiss(toastId);
    }
  };

  const removeFormCart = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    toast.dismiss();
    const toastId = toast.loading("Removing from cart...");
    try {
      const { data } = await axios.post("productremovefromcart", {
        user_type: userProfile?.user_type,
        is_admin: "0",
        device_id: localStorage.getItem("deviceId"),
        user_id: userProfile?.id,
        product_id: itemInfo?.product_id,
        cart_id: cartId,
      });
      if (data && data?.STATUS === 200) {
        toast.success(data?.MESSAGE || "Product removed from cart.");
      }
      fetchCartItems();
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Something went wrong");
    } finally {
      toast.dismiss(toastId);
    }
  };

  const debouncedUpdateCartItem = debounce(updateCartItem, 500);

  const truncateProductName = (name) => {
    if (name.length > 18) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  const truncateDescriptionName = (name) => {
    if (name.length > 18) {
      return name.substring(0, 35) + "...";
    }
    return name;
  };

  const handleCardClick = () => {
    navigate(
      `/product/${itemInfo?.id || itemInfo?.product_id}/${
        itemInfo?.product_name
      }`
    );
  };

  return (
    <div
      className="border border-[#CBCBCB] rounded-[5px] overflow-hidden flex cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="h-full w-[120px] sm:w-[130px] lg:w-[140px]">
        <img
          src={itemInfo?.product_image}
          alt=""
          className="h-full w-full object-cover"
        />
      </div>
      <div className="w-[calc(100%-120px)] sm:w-[calc(100%-130px)] lg:w-[calc(100%-140px)] px-2 py-2 lg:px-3 lg:py-4 relative">
        <button
          className="absolute h-5 w-5 bg-[#CBCBCB] text-[#555555] grid place-content-center rounded-md right-[7px] top-[7px]"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            removeFormCart();
          }}
        >
          <FaXmark />
        </button>
        <h5 className="text-xl xl:tet-[22px] font-medium !leading-none mb-1 lg:mb-2.5">
          {truncateProductName(itemInfo?.product_name || "")}
        </h5>
        <p className="text-sm font-normal !leading-[1.2] text-[#555555] line-clamp-2 lg:block">
          {truncateDescriptionName(itemInfo?.product_short_descriptio || "")}
        </p>
        <p className="text-base lg:text-lg leading-5 text-[#555555] mt-2 font-medium">
          {itemInfo?.stitching_type}: ₹{itemInfo?.stitching_price}
        </p>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-2 py-2 lg:py-3.5">
          <div className="flex items-center gap-3">
            <p className="text-base lg:text-lg leading-5 text-[#555555] font-medium">
              Qty:
            </p>
            <ProductQtyCounter
              defaultValue={itemInfo?.product_quantity}
              onChange={(value) => {
                setItemInfo((prev) => ({ ...prev, product_quantity: value }));
                debouncedUpdateCartItem(itemInfo?.child_cart_id, value);
              }}
            />
          </div>
        </div>
        <div className="pb-3">
          <p>
            <span className="text-lg lg:text-xl font-medium leading-26 pr-2 text-[#03A685]">
              ₹{itemInfo?.product_price}
            </span>
            <span className="text-sm lg:text-base font-medium leading-26 pr-1 text-[#7C7C7C] line-through">
              ₹{itemInfo?.product_mrp}
            </span>
            <span className="block sm:inline-block text-sm lg:text-base font-medium leading-26 pr-1 text-[#FF1818]">
              {itemInfo?.product_discount}% off
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default BagCard;
