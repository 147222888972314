import AddressCard from "../components/AddressCard";
import CustomSwitch from "../components/CustomSwitch";
import React, { useContext, useEffect, useState } from "react";
import { GoPlus } from "react-icons/go";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BiShoppingBag } from "react-icons/bi";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { IoIosArrowDown, IoIosCheckmark } from "react-icons/io";
import {
  Box,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { InputBase } from "@mui/material";
import { GoPencil } from "react-icons/go";
import { CiWallet } from "react-icons/ci";
import toast from "react-hot-toast";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { CartContext } from "../context/CartContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Razorpayicon from "../assets/images/razorpay.png";
import codicon from "../assets/images/imgpsh_fullsize_anim (1).png";
import { STORAGE } from "../config/config";

import SuccessModal from "../components/SucessModal";
import couponIcon from "../assets/images/Vector.png";
import { FaAngleLeft } from "react-icons/fa6";
import shoppingBag from "../assets/images/shopping-bag.png";
import shopCart from "../assets/images/shop-cart.png";
import shopBag from "../assets/images/shop-bag.png";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ChooseAddressPage = () => {
  const { cartItem } = useContext(CartContext);
  // // console.log("cartinfo", cartInfo);
  const [showAddressForm, setShowAddressForm] = React.useState(false);
  const [showCouponCode, setShowCouponCode] = React.useState(false);
  const [addresses, setAddresses] = useState([]);
  // console.log("address", addresses);
  const [activeTab, setActiveTab] = useState("Address");
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const schema = yup.object().shape({
    address_pincode: yup.string().required("Pincode is required"),
    address_landmark: yup.string().required("Landmark is required"),
    address_country: yup.string().required("Country is required"),
    address_city: yup.string().required("City is required"),
    address_state: yup.string().required("State is required"),
    address_flate_house_company: yup
      .string()
      .required("House/Flat/Office No. is required"),
    address_area_street_village: yup
      .string()
      .required("Road Name/Area/Colony is required"),
    address_name: yup.string().required("Name is required"),
    address_mobile: yup
      .string()
      .required("Phone is required")
      .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
  });
  const fetchAddresses = async () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    try {
      const { data } = await axios.get("myaddress", {
        params: {
          user_id: userProfile?.id,
          limit: "20",
          pageno: "1",
        },
      });
      if (data && data?.STATUS === 200) {
        setAddresses(data?.DATA || []);
        // console.log("address", addresses);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Failed to fetch addresses.");
    }
  };

  useEffect(() => {
    fetchAddresses();
    window.scrollTo(0, 0);
  }, []);

  const defaultValues = {
    address_pincode: "",
    address_flate_house_company: "",
    address_area_street_village: "",
    address_is_default: "0",
    address_name: "",
    address_mobile: "",
    address_country: "",
    address_state: "",
    address_city: "",
    address_landmark: "",
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const toggleAddressDrawer = (newOpen) => () => {
    !newOpen && reset();
    setShowAddressForm(newOpen);
  };

  const toggleCouponCodeDrawer = (newOpen) => () => {
    !newOpen && reset();
    setShowCouponCode(newOpen);
  };

  async function saveAddress(data) {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    const toastId = toast.loading("saving...");
    try {
      const { data: saveAddressData } = await axios.post("saveaddress", {
        ...data,
        user_id: localStorage.getItem("userId") || userProfile?.id,
      });

      if (saveAddressData && saveAddressData?.STATUS === 200) {
        await fetchAddresses();
        setShowAddressForm(false);
        reset();
        toast.success("Address saved.");
        // console.log("ahahhah::", saveAddress);
      } else if (saveAddressData && saveAddressData?.STATUS === 400) {
        // console.log("kkmkmkmk:", saveAddressData.DATA);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.response?.data?.MESSAGE || "Failed to save address.");
    } finally {
      toast.dismiss(toastId);
    }
  }
  const onSubmit = (data) => {
    saveAddress(data);
  };
  const paymentMethods = [
    {
      label: "COD (Cash on Delivery)",
      value: "cod",
      image: codicon,
    },
    {
      label: "Use Razorpay Payments",
      value: "razorpay",
      image: Razorpayicon,
    },
  ];

  useEffect(() => {
    const storedAddress = JSON.parse(localStorage.getItem("selectedAddress"));

    if (storedAddress) {
      setSelectedAddress(storedAddress);
      handleOrderSummary(storedAddress.address_id);
    } else if (addresses.length > 0) {
      const initialAddress = addresses[0];
      setSelectedAddress(initialAddress);
      handleOrderSummary(initialAddress.address_id);
    }
  }, [addresses]);

  const handleSelectedAddress = (address) => {
    setSelectedAddress(address);
    localStorage.setItem("selectedAddress", JSON.stringify(address));
    toast.success("Your Address Add Successful");
    handleOrderSummary(address.address_id);
  };

  // -----------------------------for payment------------------------------------

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentMethods.value
  );

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handlePayment = () => {
    if (!selectedPaymentMethod) {
      toast.error("Please select a payment method");
      return;
    }
    if (selectedPaymentMethod === "cod") {
      handleCodPayment();
      setIsButtonDisabled(true);
      localStorage.removeItem("selectedAddress");
    } else if (selectedPaymentMethod === "razorpay") {
      handleRazorpayPayment();
      localStorage.removeItem("selectedAddress");
    }
  };

  const handleRazorpayPayment = async () => {
    var options = {
      description: "Credits towards consultation",
      image: "https://i.imgur.com/3g7nmJC.png",
      currency: "INR",

      key: "rzp_live_zyNwqcKeuYsh2b",
      amount: cartInfo?.total_net_amount * 100,
      name: "Kapoor Lehnga",
      prefill: {
        email: "ggg@gmail.com",
        contact: "8545565211",
        name: "ggg",
      },
      theme: { color: "red" },

      handler: async function (response) {
        if (response.razorpay_payment_id) {
          try {
            const userProfile = JSON.parse(
              localStorage.getItem(STORAGE.USERDETAIL)
            );
            const deviceId = localStorage.getItem(STORAGE?.DEVICEID);
            const response = await axios.post("saveorder", {
              device_id: deviceId,
              is_admin: 0,
              is_mobile: 1,
              user_type: userProfile?.user_type,
              payment_from: "app",

              payment_method: paymentMethods[1].value,
              shipping_name: selectedAddress
                ? selectedAddress.address_name
                : "",
              shipping_mobile: selectedAddress
                ? selectedAddress.address_mobile
                : "",
              shipping_flate_house_company: selectedAddress
                ? selectedAddress.address_flate_house_company
                : "",
              shipping_area_street_village: selectedAddress
                ? selectedAddress.address_area_street_village
                : "",
              shipping_landmark: selectedAddress
                ? selectedAddress.address_landmark
                : "",
              shipping_pincode: selectedAddress
                ? selectedAddress.address_pincode
                : "",
              shipping_city: selectedAddress
                ? selectedAddress.address_city
                : "",
              shipping_state: selectedAddress
                ? selectedAddress.address_state
                : "",
              shipping_country: selectedAddress
                ? selectedAddress.address_country
                : "",
              user_id: userProfile ? userProfile.id : "",
              code: userProfile?.user_refer_id,
              total_weight: cartItem,
            });

            if (response.data.STATUS === 200) {
              toast.success(response.data.MESSAGE);
              setModalMessage(response.data.MESSAGE);
              setOpenModal(true);
            } else if (response.data.STATUS === 400) {
              console.error("COD payment failed:");
              toast.error(response.data.MESSAGE);
            }
          } catch (error) {
            console.error(
              "An error occurred while processing COD payment:",
              error.message
            );
          }
        }
      },

      modal: {
        ondismiss: function () {},
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handleCodPayment = async () => {
    try {
      const userProfile = JSON.parse(localStorage.getItem(STORAGE.USERDETAIL));
      const deviceId = localStorage.getItem(STORAGE?.DEVICEID);
      const response = await axios.post("saveorder", {
        device_id: deviceId,
        is_admin: 0,
        is_mobile: 1,
        user_type: userProfile?.user_type,
        payment_from: "app",
        payment_method: paymentMethods[0].value,
        shipping_name: selectedAddress ? selectedAddress.address_name : "",
        shipping_mobile: selectedAddress ? selectedAddress.address_mobile : "",
        shipping_flate_house_company: selectedAddress
          ? selectedAddress.address_flate_house_company
          : "",
        shipping_area_street_village: selectedAddress
          ? selectedAddress.address_area_street_village
          : "",
        shipping_landmark: selectedAddress
          ? selectedAddress.address_landmark
          : "",
        shipping_pincode: selectedAddress
          ? selectedAddress.address_pincode
          : "",
        shipping_city: selectedAddress ? selectedAddress.address_city : "",
        shipping_state: selectedAddress ? selectedAddress.address_state : "",
        shipping_country: selectedAddress
          ? selectedAddress.address_country
          : "",
        user_id: userProfile ? userProfile.id : "",
        code: userProfile?.user_refer_id,
        total_weight: cartItem,
      });

      if (response.data.STATUS === 200) {
        toast.success(response.data.MESSAGE);
        setModalMessage(response.data.MESSAGE);
        setOpenModal(true);
      } else if (response.data.STATUS === 400) {
        console.error("COD payment failed:");
        toast.error(response.data.MESSAGE);
      }
    } catch (error) {
      console.error(
        "An error occurred while processing COD payment:",
        error.message
      );
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleactivepayment = () => {
    if (!selectedAddress) {
      toast.error(
        "please add address or click on delivery here to select address"
      );
    } else {
      setActiveTab("Payment");
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // -----------------------------for payment------------------------------------
  // ------------------------------
  const [couponData, setCouponData] = useState([]);
  const [selectedCodeName, setSelectedCodeName] = useState("");
  const fetchCouponCode = async () => {
    try {
      const response = await axios.get("/allcoupan");
      if (response.data.STATUS === 200) {
        setCouponData(response.data.DATA.coupon_banner);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching the coupon code:",
        error.message
      );
    }
  };
  const handleCodeSelection = (codeName) => {
    setSelectedCodeName(codeName);
    setShowCouponCode(false);
  };
  useEffect(() => {
    fetchCouponCode();
  }, []);

  useEffect(() => {}, [couponData, selectedCodeName]);

  const verifyCouponCode = () => {
    const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));
    if (selectedCodeName && userProfile) {
      axios
        .post("/verifycode", {
          device_id: localStorage.getItem(STORAGE?.DEVICEID),
          user_id: userProfile.id,
          coupon_code: selectedCodeName,
        })
        .then((response) => {
          if (response.data.STATUS === 200) {
            toast.success(response.data.MESSAGE);
            setCartInfo((prevCartInfo) => ({
              ...prevCartInfo,
              applied_coupon_code: response.data.coupon_code,
              applied_coupon_amount: response.data.coupon_amount,
              total_discount_amount: response.data.discount_amount,
            }));
            window.location.reload();
          }
          if (response?.data?.STATUS === 400) {
            toast.error(response?.data?.MESSAGE);
          }
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    } else {
      console.error("User profile or selected code name is missing.");
    }
  };
  // ------------------------------

  const userProfile = JSON.parse(localStorage.getItem(STORAGE?.USERDETAIL));

  const filteredPaymentMethods =
    userProfile?.user_type === "btoc"
      ? paymentMethods // Show both payment methods for "btob"
      : paymentMethods.filter((method) => method.value !== "cod");

  const [selectedAddress, setSelectedAddress] = useState(null);

  const truncateProductName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 10) + "...";
    }
    return name;
  };

  const truncateProductHeading = (name) => {
    if (name.length > 29) {
      return name.substring(0, 29) + "...";
    }
    return name;
  };

  const [cartInfo, setCartInfo] = useState({});

  const handleOrderSummary = async (addressId) => {
    try {
      const userProfile = JSON.parse(localStorage.getItem(STORAGE.USERDETAIL));
      const deviceId = localStorage.getItem(STORAGE?.DEVICEID);
      const { data } = await axios.post("order_summary", {
        device_id: deviceId,
        is_admin: 0,
        is_mobile: 1,
        user_type: userProfile?.user_type,
        payment_from: "app",
        payment_method: paymentMethods[0].value,
        payment_reference_number: "",
        address_id: addressId,
        user_id: userProfile ? userProfile.id : "",
        total_weight: cartItem,
      });

      console.log("Response from server:", data);

      if (data?.STATUS === 200) {
        const shippingDetails = data?.DATA?.shipping_details;
        setCartInfo(shippingDetails);
        console.log("Shipping Details:", shippingDetails);
      } else if (data?.STATUS === 400) {
        console.error("Error:", data?.MESSAGE);
      }
    } catch (error) {
      console.error(
        "An error occurred while processing COD payment:",
        error.message
      );
    }
  };

  return (
    <>
      <TopBar />
      <Header />
      <div>
        <div className="max-w-[1784px] mx-auto px-3">
          <div className="max-w-7xl mx-auto flex flex-col items-start mt-8 xl:mt-14">
            <div className="flex w-[100%] items-center gap-3 xl:gap-5">
              <div
                className={`flex items-center gap-2 lg:gap-5 cursor-pointer ${
                  activeTab === "SignUp" ? "text-black" : "text-black"
                }`}
              >
                <span className="flex-shrink-0 h-5 w-5 md:h-[30px] md:w-[30px] grid place-content-center rounded-full bg-black text-white">
                  <IoIosCheckmark className="text-2xl md:text-4xl" />
                </span>
                <span className="sm:text-lg lg:text-[23px] xl:text-2xl !leading-none font-medium">
                  Sign Up
                </span>
              </div>
              <div className="flex-1">
                <span
                  className="border-t-[3px] border-dashed border-black w-full block "
                  style={{ border: "2px dashed black" }}
                ></span>
              </div>
              <div
                className={`flex items-center gap-2 lg:gap-5 cursor-pointer ${
                  activeTab === "Address" ? "text-black" : "text-black"
                }`}
                onClick={() => handleTabClick("Address")}
              >
                <span className="flex-shrink-0 h-5 w-5 md:h-[30px] md:w-[30px] grid place-content-center rounded-full bg-black text-white">
                  <GoPencil className="text-[10px] md:text-xl" />
                </span>
                <span className="sm:text-lg lg:text-[23px] xl:text-2xl !leading-none font-medium">
                  Address
                </span>
              </div>
              <div className="flex-1">
                <span
                  className={`paymentborder ${
                    activeTab === "Payment"
                      ? "paymentborder-black"
                      : "paymentborder-gray"
                  }`}
                />
              </div>
              <div
                className={`flex items-center gap-2 lg:gap-5 cursor-pointer ${
                  activeTab === "Payment" ? "text-black" : "text-gray-400"
                }`}
                onClick={() => handleTabClick("Payment")}
              >
                <span
                  className={`flex-shrink-0 h-5 w-5 md:h-[30px] md:w-[30px] grid place-content-center rounded-full ${
                    activeTab === "Payment" ? "bg-black" : "bg-gray-400"
                  } text-white text-xl md:text-2xl !leading-none font-medium`}
                >
                  <CiWallet className="text-[10px] md:text-xl" />
                </span>
                <span className="sm:text-lg lg:text-[23px] xl:text-2xl !leading-none font-medium">
                  Payment
                </span>
              </div>
            </div>
            <div className="mt-2 w-[100%]">
              {activeTab === "SignUp" && <div>Sign Up Content</div>}
              {activeTab === "Address" && (
                <>
                  <div className="flex justify-between items-center flex-wrap my-3 xl:mt-5 xl:mb-[52px] -mx-3 gap-y-4">
                    <div className="px-3">
                      <h2 className="text-2xl 2xl:text-[40px] font-medium font-jost !leading-none mb-[15px]">
                        Choose Address
                      </h2>
                      <p className="sm:text-xl lg:text-[23px] !leading-[120%] font-normal text-[#535353]">
                        Detailed address will help our delivery partner reach
                        your doorstep quickly
                      </p>
                    </div>
                    <div className="px-3">
                      <img
                        src="/images/map-address.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between flex-wrap mt-10 mb-[52px] -mx-3 gap-y-4">
                    <div className="w-full lg:w-[57%] px-3 xl:pr-[26px]">
                      <div className="grid sm:grid-cols-2 lg:grid-cols-2 gap-5 ">
                        {addresses?.map((address, index) => {
                          return (
                            <AddressCard
                              key={"address" + index}
                              info={address}
                              fetchAddresses={fetchAddresses}
                              onSelectAddress={handleSelectedAddress}
                            />
                          );
                        })}

                        <div>
                          <button
                            className="bg-[#F6F6F6] border border-[#CAXCACA] rounded-[10px] p-4 md:p-5 xl:p-[25px] grid place-content-center w-full h-full"
                            onClick={() => {
                              setShowAddressForm(true);
                            }}
                          >
                            <span className="block mb-7 text-center">
                              <GoPlus className="text-4xl text-black mx-auto" />
                            </span>
                            <h3 className="xs:text-lg md:text-xl xl:text-[26px] !leading-none font-normal font-jost">
                              Add New Address
                            </h3>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-[43%] px-3 xl:pl-[26px]">
                      {addresses.length > 0 && addresses[0]?.address_id ? (
                        <div className="bg-[#F6F6F6] border border-[#CACACA] rounded-[10px] p-3 sm:p-4 md:p-5 xl:p-[25px] grid gap-5">
                          <Accordion
                            className="shadow-none !rounded-[10px] !my-0 accordion-main"
                            defaultExpanded
                          >
                            <AccordionSummary
                              className="h-[60px] md:h-20 rounded-[10px]"
                              expandIcon={
                                <IoIosArrowDown className="text-2xl flex-shrink-0 sm:m-1 text-black" />
                              }
                              aria-controls="panel11-content"
                              id="panel11-head"
                            >
                              <div className="flex items-center justify-between w-full pe-[15px]">
                                <span className="text-base leading-5 font-medium font-jost flex items-center gap-3 xl:gap-x-5">
                                  <BiShoppingBag className="text-xl xl:text-4xl" />
                                  <span className="text-lg md:text-2xl xl:text-[26px] font-medium !leading-none capitalize">
                                    {" "}
                                    Bag
                                  </span>
                                </span>
                                <span className="text-lg md:text-2xl xl:text-[26px] font-medium !leading-none text-[#525252]">
                                  {cartInfo?.cartcount} Items
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails className="!p-0">
                              <div className="bg-white p-3.5 xl:p-5 !pt-0 rounded-[10px] grid gap-3 sm:gap-5">
                                {cartInfo?.cartdata?.map((item) => {
                                  return (
                                    <div className="bg-[#F6F6F6] rounded-[5px] overflow-hidden flex">
                                      <div className="h-full w-[100px] lg:w-[127px] mx-auto ">
                                        <img
                                          src={item?.product_image}
                                          alt=""
                                          className="h-full w-full object-cover"
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="w-[calc(100%-100px)] lg:w-[calc(100%-127px)] px-2 py-2 lg:px-3 lg:py-4 relative">
                                        <h5 className="text-xl xl:text-[22px] font-medium !leading-none  mb-1 lg:mb-2.5 ">
                                          {item?.product_name}
                                        </h5>
                                        <p className="text-base font-normal leading-6  text-[#555555] line-clamp-1">
                                          {item?.product_short_description}
                                        </p>
                                        <div className="flex items-center gap-3">
                                          <p className="text-base lg:text-lg leading-5 text-[#555555] font-medium">
                                            Qty:{" "}
                                            <span>
                                              {item?.product_quantity}
                                            </span>
                                          </p>
                                        </div>
                                        <p>
                                          <span className="xs:text-lg lg:text-xl font-medium leading-26  pr-2 text-[#03A685]">
                                            ₹{item?.product_price}
                                          </span>
                                          <span className="text-sm lg:text-base font-medium leading-26  pr-1 text-[#7C7C7C] line-through">
                                            ₹{item?.product_mrp}
                                          </span>
                                          <span className="block xs:inline-block text-sm lg:text-base font-medium leading-26  pr-1 text-[#FF1818]">
                                            {item?.product_discount}% off
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          {userProfile?.user_type === "btoc" ? (
                            <>
                              <Accordion
                                className="shadow-none !rounded-[10px] !my-0 accordion-main"
                                defaultExpanded
                              >
                                <AccordionSummary
                                  className="h-[60px] md:h-20 rounded-[10px]"
                                  expandIcon={
                                    <FaAngleLeft className="text-2xl flex-shrink-0 sm:m-1 text-black" />
                                  }
                                  aria-controls="panel11-content"
                                  id="panel11-head"
                                >
                                  <div className="flex items-center justify-between w-full pe-[15px]">
                                    <span className="text-base leading-5 font-medium font-jost flex items-center gap-3 xl:gap-x-5">
                                      <img
                                        src={couponIcon}
                                        className="text-xl xl:text-4xl"
                                        loading="lazy"
                                      />
                                      <span className="text-lg md:text-2xl xl:text-[26px] font-medium !leading-none capitalize">
                                        Coupon Code
                                      </span>
                                    </span>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className="!p-0">
                                  <div className="bg-white p-3.5 xl:p-5 !pt-0 rounded-[10px] grid gap-3 sm:gap-5">
                                    <div className="flex justify-between items-center">
                                      <div className="w-[75%]">
                                        <InputBase
                                          classes={{ input: "py-0" }}
                                          type="text"
                                          placeholder="Enter Coupon code"
                                          className="p-2 border border-[#CDCDCD] rounded w-[100%]"
                                          value={selectedCodeName}
                                          onChange={(e) =>
                                            setSelectedCodeName(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="w-[20%]">
                                        {selectedCodeName === "" ? (
                                          <button
                                            className="bg-[#E9B159] p-3 rounded w-full font-bold text-white text-xs"
                                            onClick={() =>
                                              setShowCouponCode(true)
                                            }
                                          >
                                            Select Coupon
                                          </button>
                                        ) : (
                                          <button
                                            className="bg-[#E9B159] p-3 rounded w-full font-bold text-white"
                                            onClick={verifyCouponCode}
                                          >
                                            Apply
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            ""
                          )}
                          <Accordion
                            className="shadow-none !rounded-[10px] !my-0 accordion-main"
                            defaultExpanded
                          >
                            <AccordionSummary
                              className="h-[60px] md:h-20 rounded-[10px]"
                              expandIcon={
                                <IoIosArrowDown className="text-2xl flex-shrink-0 sm:m-1 text-black" />
                              }
                              aria-controls="panel2-content"
                              id="panel2-header"
                            >
                              <span className="text-base leading-5 font-medium font-jost flex items-center gap-3 xl:gap-x-5">
                                <HiOutlineCurrencyRupee className="text-xl xl:text-4xl" />
                                <span className="text-lg md:text-2xl xl:text-[26px] font-medium !leading-none capitalize">
                                  Price Details
                                </span>
                              </span>
                            </AccordionSummary>
                            <AccordionDetails className="!p-0">
                              <div className="bg-white p-3.5 xl:p-5 !pt-0 rounded-[10px]">
                                <div className="border border-[#CBCBCB] bg-[#F8F8F8] px-3 lg:px-4 py-3  lg:py-5 rounded-[5px]">
                                  <div>
                                    <h5 className="text-xl md:text-2xl mb-3 font-medium !leading-none ">
                                      Price Summary
                                    </h5>
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                      Bag Total
                                    </p>

                                    <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                      ₹{cartInfo?.total_gross_amount || 0}
                                    </p>
                                  </div>

                                  {cartInfo?.applied_coupon_code && (
                                    <>
                                      <div className="flex items-center justify-between">
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                          Coupon code
                                        </p>
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                          {cartInfo.applied_coupon_code}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-between">
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                          Coupon Amount
                                        </p>
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#f9364f]">
                                          -₹
                                          {cartInfo.applied_coupon_amount}
                                        </p>
                                      </div>
                                    </>
                                  )}

                                  <>
                                    <div className="flex items-center justify-between">
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                        Bag discount
                                      </p>
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#f9364f]">
                                        -₹
                                        {cartInfo?.total_discount_amount || 0}
                                      </p>
                                    </div>
                                  </>

                                  {userProfile?.user_type === "btob" ? (
                                    <>
                                      <div className="flex items-center justify-between">
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                          GST {cartInfo?.gst_percentage}%
                                        </p>
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                          ₹{cartInfo?.gst_amount || 0}
                                        </p>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div className="flex items-center justify-between">
                                    <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                      Stiching Price
                                    </p>
                                    <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                      ₹{cartInfo?.total_stiching_price || 0}
                                    </p>
                                  </div>

                                  {selectedAddress &&
                                    cartInfo?.order_shipping_charge_amount !==
                                      undefined && (
                                      <div className="flex items-center justify-between">
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                          Shipping Charge
                                        </p>
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                          ₹
                                          {cartInfo?.order_shipping_charge_amount ||
                                            0}
                                        </p>
                                      </div>
                                    )}

                                  <div className="flex items-center justify-between">
                                    <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                                      Total Pay
                                    </p>
                                    <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                                      ₹{cartInfo?.total_net_amount || 0}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                          <div>
                            <button
                              className="w-full bg-[#E9B159] p-1 sm:p-3 xl:p-7 text-lg lg:text-2xl font-medium leading-10 text-center text-white rounded-md mt-2.5"
                              onClick={handleactivepayment}
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              )}
              {activeTab === "Payment" && (
                <>
                  <div className="flex justify-between items-center flex-wrap my-6 xl:mt-10 xl:mb-[52px] -mx-3 gap-y-4">
                    <div className="px-3">
                      <h2 className="text-2xl md:text-4xl 2xl:text-[50px] font-medium font-jost !leading-none mb-[15px]">
                        Payment Method
                      </h2>
                      <p className="sm:text-lg lg:text-[26px] !leading-[120%] font-normal text-[#535353]">
                        Detailed address will help our delivery partner reach
                        your doorstep quickly
                      </p>
                    </div>
                    <div className="px-3">
                      <img src="/images/map-address.png" alt="" />
                    </div>
                  </div>
                  <div className="flex justify-between flex-wrap mt-10 mb-[52px] -mx-3 gap-y-4">
                    <div className="w-full lg:w-[43%] px-3 xl:pl-[26px]">
                      {selectedAddress &&
                        cartInfo?.order_shipping_charge_amount !==
                          undefined && (
                          <div className="bg-[#F6F6F6] border border-[#CACACA] rounded-[10px] p-3 sm:p-4 md:p-5 xl:p-[25px] grid gap-5">
                            <Accordion
                              className="shadow-none !rounded-[10px] !my-0 accordion-main"
                              defaultExpanded
                            >
                              <AccordionSummary
                                className="h-[60px] md:h-20 rounded-[10px]"
                                expandIcon={
                                  <IoIosArrowDown className="text-2xl flex-shrink-0 sm:m-1 text-black" />
                                }
                                aria-controls="panel2-content"
                                id="panel2-header"
                              >
                                <span className="text-base leading-5 font-medium font-jost flex items-center gap-3 xl:gap-x-5">
                                  <HiOutlineCurrencyRupee className="text-xl xl:text-4xl" />
                                  <span className="text-lg md:text-2xl xl:text-[26px] font-medium !leading-none capitalize">
                                    Price Details
                                  </span>
                                </span>
                              </AccordionSummary>
                              <AccordionDetails className="!p-0">
                                <div className="bg-white p-3.5 xl:p-5  rounded-[10px]">
                                  <div className="border border-[#CBCBCB] bg-[#F8F8F8] px-3 lg:px-4 py-3  lg:py-5 rounded-[5px]">
                                    <div>
                                      <h5 className="text-xl md:text-2xl mb-3 font-medium !leading-none ">
                                        Price Summary
                                      </h5>
                                    </div>
                                    <div className="flex items-center justify-between">
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                        Bag Total
                                      </p>
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                        ₹{cartInfo?.total_gross_amount || 0}
                                      </p>
                                    </div>

                                    {cartInfo?.applied_coupon_code && (
                                      <>
                                        <div className="flex items-center justify-between">
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                            Coupon code
                                          </p>
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                            {cartInfo.applied_coupon_code}
                                          </p>
                                        </div>
                                        <div className="flex items-center justify-between">
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#555555]">
                                            Coupon Amount
                                          </p>
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal leading-none text-[#f9364f]">
                                            -₹
                                            {cartInfo.applied_coupon_amount}
                                          </p>
                                        </div>
                                      </>
                                    )}

                                    <>
                                      <div className="flex items-center justify-between">
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                          Bag Discount
                                        </p>
                                        <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none text-[#f9364f]">
                                          -₹
                                          {cartInfo?.total_discount_amount || 0}
                                        </p>
                                      </div>
                                    </>
                                    {userProfile?.user_type === "btob" ? (
                                      <>
                                        <div className="flex items-center justify-between">
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                            GST {cartInfo?.gst_percentage}%
                                          </p>
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                            ₹{cartInfo?.gst_amount || 0}
                                          </p>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    <div className="flex items-center justify-between">
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                        Stiching Price
                                      </p>
                                      <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                        ₹{cartInfo?.total_stiching_price || 0}
                                      </p>
                                    </div>

                                    {selectedAddress &&
                                      cartInfo?.order_shipping_charge_amount !==
                                        undefined && (
                                        <div className="flex items-center justify-between">
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                            Shipping Charge
                                          </p>
                                          <p className="sm:text-base xl:text-xl py-2 xl:py-2.5 font-normal !leading-none  text-[#555555]">
                                            ₹
                                            {cartInfo?.order_shipping_charge_amount ||
                                              0}
                                          </p>
                                        </div>
                                      )}

                                    <div className="flex items-center justify-between">
                                      <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                                        Total Pay
                                      </p>
                                      <p className="sm:text-base xl:text-xl pt-2.5 font-semibold !leading-none  text-[#03A685]">
                                        ₹{cartInfo?.total_net_amount || 0}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                    </div>

                    <div className="bg-[#F6F6F6] p-3 sm:p-4 md:p-5 xl:p-[25px] rounded-[10px]">
                      <div className="mb-5">
                        <h5 className="text-xl md:text-2xl mb-3 font-medium leading-none">
                          Payment Method
                        </h5>
                      </div>
                      <RadioGroup
                        aria-labelledby="payment-method-label"
                        onChange={(e) =>
                          setSelectedPaymentMethod(e.target.value)
                        }
                        name="payment-method-group"
                      >
                        {filteredPaymentMethods.map((method, index) => (
                          <div
                            key={index}
                            className="flex justify-between items-center gap-4 p-3.5 xl:p-5 bg-white mb-2 rounded-[10px]"
                          >
                            <div className="w-14 p-2 bg-[#F6F6F6] rounded">
                              <img
                                src={method.image}
                                alt={method.label}
                                className="w-10 h-10"
                                loading="lazy"
                              />
                            </div>
                            <div className="flex-1">
                              <h6 className="text-xl md:text-2xl mb-3 font-medium leading-none">
                                {method.label}
                              </h6>
                            </div>
                            <FormControlLabel
                              value={method.value}
                              control={
                                <Radio
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#e9b159",
                                    },
                                  }}
                                  className="gap-3"
                                />
                              }
                              label=""
                            />
                          </div>
                        ))}
                      </RadioGroup>
                      <div className="mt-5">
                        <button
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="large"
                          onClick={handlePayment}
                          className="w-full bg-[#E9B159] p-1 sm:p-3 xl:p-7 text-lg lg:text-2xl font-medium leading-10 text-center text-white rounded-md mt-2.5"
                          disabled={isButtonDisabled}
                        >
                          Payment
                        </button>
                        <SuccessModal
                          open={openModal}
                          handleClose={handleCloseModal}
                          message={modalMessage}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        open={showAddressForm}
        onClose={toggleAddressDrawer(false)}
        anchor="right"
      >
        <Box
          role="presentation"
          className="scrollbar max-w-[300px] lg:!max-w-[450px] w-screen"
        >
          <div className="w-screen max-w-[300px] lg:max-w-[450px] h-full bg-white absolute top-0 right-0">
            <div className="bag-header flex justify-between items-center border-b border-[#C5C5C5] p-3">
              <h3 className="text-xl md:text-2xl xl:text-[26px] !leading-none font-medium">
                New Address
              </h3>
              <button
                className="w-10 aspect-square flex items-center justify-center"
                onClick={() => {
                  setShowAddressForm(false);
                }}
              >
                <IoIosClose size={40} />
              </button>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="h-[calc(100%-97px)]"
            >
              <div className="p-6 lg:pt-[30px] lg:pb-10 lg:px-11 h-[calc(100%-53px)] md:h-[calc(100%-101px)]  lg:h-[calc(100%-133px)] xl:h-[calc(100%-165px)] overflow-auto scrollbar">
                <h4 className="text-xl md:text-2xl xl:text-[22px] !leading-none font-medium mb-3 xl:mb-6">
                  Address
                </h4>
                <div className="grid gap-y-3">
                  <div className="w-full">
                    <Controller
                      name="address_country"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Country"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_country?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_name"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Name"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors?.address_name?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_mobile"
                      control={control}
                      rules={{
                        required: "Mobile number is required",
                        validate: (value) => {
                          const regex = /^[0-9]{10}$/;
                          if (!regex.test(value)) {
                            return "Mobile number must be exactly 10 digits";
                          }
                          return true;
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <InputBase
                            {...field}
                            classes={{ input: "py-0" }}
                            placeholder="Mobile Number"
                            className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                            inputProps={{
                              maxLength: 10,
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              onInput: (e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                );
                              },
                            }}
                          />
                          <p className="text-red-500">
                            {errors?.address_mobile?.message}
                          </p>
                        </>
                      )}
                    />
                  </div>
                  {/* <div className="w-full">
                    <Controller
                      name="address_flate_house_company"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              type="number"
                              classes={{ input: "py-0" }}
                              placeholder="Flat,House,No,Building"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_flate_house_company?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div> */}
                  <div className="w-full">
                    <Controller
                      name="address_flate_house_company"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              placeholder="Flat,House,No,Building"
                              sx={{ "&::placeholder": { color: "#858585" } }}
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_flate_house_company?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_area_street_village"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              placeholder="Area,Street,Village"
                              sx={{ "&::placeholder": { color: "#858585" } }}
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_area_street_village?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_landmark"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              // type="text"
                              classes={{ input: "py-0" }}
                              placeholder="Landmark"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_landmark?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_pincode"
                      control={control}
                      render={({ field }) => {
                        // const errorMessage = getFieldState(field.name)?.error?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="Pincode"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                              type="number"
                            />
                            <p className="text-red-500">
                              {errors.address_pincode?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <Controller
                      name="address_city"
                      control={control}
                      render={({ field }) => {
                        // const errorMessage = getFieldState(field.name)?.error?.message;
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="City"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                              // type="text"
                            />
                            <p className="text-red-500">
                              {errors.address_city?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Controller
                      name="address_state"
                      control={control}
                      render={({ field }) => {
                        return (
                          <>
                            <InputBase
                              classes={{ input: "py-0" }}
                              placeholder="State"
                              className="h-12 sm:h-[60px] xl:h-10 w-full border-b border-[#cdcdcd] !text-lg sm:!text-xl xl:!text-2xl 3xl:!text-[28px] !leading-none font-jost bg-[#f5f5f5] px-4 py-3 xl:p-6 text-black"
                              {...field}
                            />
                            <p className="text-red-500">
                              {errors.address_state?.message}
                            </p>
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <h4 className="text-xl md:text-2xl xl:text-[22px] !leading-none font-medium mb-3">
                      Contact
                    </h4>
                    <p className="xs:text-lg lg:text-[19px] !leading-[120%] font-normal text-[#696868]">
                      Information provided here will be used to contact you for
                      delivery updates
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-3 md:p-6 lg:px-11 2xl:py-10">
                <button
                  className="w-full bg-[#E9B159] p-2 lg:p- text-lg lg:text-2xl font-medium !leading-tight text-center text-white"
                  type="submit"
                >
                  Ship to this address
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Drawer>

      <Drawer
        open={showCouponCode}
        onClose={toggleCouponCodeDrawer(false)}
        anchor="right"
      >
        <Box
          role="presentation"
          className="scrollbar max-w-[300px] lg:!max-w-[450px] w-screen"
        >
          <div className="w-screen max-w-[300px] lg:max-w-[450px] h-full bg-white absolute top-0 right-0">
            <div className="bag-header flex justify-between items-center border-b border-[#C5C5C5] p-3">
              <h3 className="text-xl md:text-2xl !leading-none font-medium">
                Coupon
              </h3>
              <button
                className="w-10 aspect-square flex items-center justify-center"
                onClick={() => {
                  setShowCouponCode(false);
                }}
              >
                <IoIosClose size={40} />
              </button>
            </div>
            <div className="p-3">
              {couponData.length > 0 ? (
                couponData.map((code, index) => (
                  <div
                    key={index}
                    className="border-solid border-2 border-gray-200 mb-2"
                  >
                    <div className="flex w-full">
                      <div className="bg-[#FFEBEC] px-10 py-1 text-md font-bold relative z-40 text-red-600 rounded-r-lg w-[30%] text-center capitalize">
                        {truncateProductName(code?.title || "")}
                        <img
                          className="w-5 top-2 left-3 absolute"
                          src={shoppingBag}
                          loading="lazy"
                        />
                        <img
                          className="w-5 absolute top-4 right-3 transform rotate-45"
                          src={shopCart}
                          loading="lazy"
                        />
                        <img
                          className="w-8 absolute bottom-0 left-0"
                          src={shopBag}
                          loading="lazy"
                        />
                      </div>
                      <div className="grid items-center text-start w-[50%] ps-2">
                        <div className="text-md font-bold text-black-600">
                          {truncateProductHeading(code?.heading || "")}
                        </div>
                        <div className="text-gray-400 text-sm">
                          Use Code: {code?.name}
                        </div>
                      </div>
                      <div className="grid items-center ">
                        <button
                          className="bg-black text-white text-sm font-bold px-5 py-1 rounded-[10px] me-3"
                          onClick={() => handleCodeSelection(code?.name)}
                        >
                          COPY
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No coupons available</p>
              )}
            </div>
          </div>
        </Box>
      </Drawer>
      <Footer />
    </>
  );
};

export default ChooseAddressPage;
