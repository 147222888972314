import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import PhotoVideoSwiper from "../components/PhotoVideoSwiper";
import GoogleReviewSwiper from "../components/GoogleReviewSwiper";
import toast from "react-hot-toast";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { Circles } from "react-loader-spinner";
import TopBar from "../components/TopBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const OurStorePage = () => {
  const breadcrumbArray = [
    <Link
      to="/"
      underline="hover"
      key="1"
      color="inherit"
      className="text-[#666666] text-base lg:text-md !leading-[1.16] font-normal font-jost  hover:underline capitalize"
    >
      Home
    </Link>,
    <p
      key={2}
      className="text-[#A36300] text-base lg:text-md !leading-[1.16] font-normal font-jost  capitalize"
    >
      OUR Store
    </p>,
  ];

  const [selectedBranch, setSelectedBranch] = useState();
  const [storeInfos, setStoreInfos] = useState();
  const [loading, setLoading] = useState(true);

  const fetchStoreInfos = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get("ourstore");
      if (data && data?.STATUS === 200) {
        setStoreInfos(data?.DATA?.Branch_Details);
        console.log("storedata::", data?.DATA?.Branch_Details);
        setSelectedBranch(data?.DATA?.Branch_Details[0]?.id);
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.data?.MESSAGE || err?.message || "Failed to fetch."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoreInfos();
  }, []);

  return (
    <>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader-circle-9">
              Kapoor
              <span></span>
            </div>
          </div>
        </div>
      )}
      <TopBar />
      <Header />
      <Breadcrumb list={breadcrumbArray} />
      <div>
        <div className="w-full">
          <img
            src="/images/our-store-elem.png"
            className="w-full object-cover"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="max-w-[1600px] mx-auto px-3">
          <div className="flex flex-wrap -mx-3 -mt-8 md:-mt-16 lg:-mt-24 2xl:-mt-[180px] relative mb-10 md:mb-[60px] gap-y-3">
            {storeInfos?.map((item, index) => {
              const information = item?.Address?.split("\n");
              return (
                <button
                  type="button"
                  className="w-full md:w-4/12 px-3 relative"
                  key={"branch" + index}
                  onClick={() => {
                    setSelectedBranch(item.id);
                  }}
                >
                  {selectedBranch === item.id && (
                    <div className="absolute top-4 right-8">
                      <FaCheckCircle color="green" size={20} />
                    </div>
                  )}
                  <div className="bg-[#F0F0F0] px-3 md:px-[18px] py-6 h-full">
                    <h6 className="text-lg md:text-xl xl:text-[25px] font-medium !leading-tight mb-6">
                      {information?.[0]}
                    </h6>
                    <p className="xs:text-lg md:text-sm xl:text-[18px] !leading-[1.1] mb-3 md:mb-[18px]">
                      {information?.[1]}
                      {information?.[2]}
                    </p>
                    <p className="xs:text-lg md:text-sm xl:text-[18px] !leading-[1.1] mb-3 md:mb-[18px]">
                      {information?.[3]}
                    </p>
                    <p className="xs:text-lg md:text-sm xl:text-[18px] !leading-[1.1] mb-3 md:mb-[18px]">
                      {information?.[4]}
                    </p>
                    <p className="xs:text-lg md:text-sm xl:text-[18px] !leading-tight mb-3 md:mb-[18px]">
                      {information?.[5]}
                    </p>
                  </div>
                </button>
              );
            })}
          </div>
          <div className="mb-10 md:mb-[60px]">
            <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-5 md:mb-9">
              Photos & Videos
            </h2>
            <PhotoVideoSwiper
              info={storeInfos?.find((item) => item.id === selectedBranch)}
            />
          </div>
          <div className="mb-10 md:mb-[60px]">
            <h2 className="text-2xl sm:text-3xl md:text-3xl 2xl:text-4xl !leading-none font-medium font-jost mb-5 md:mb-9">
              Photos & Videos
            </h2>
            <GoogleReviewSwiper
              info={storeInfos?.find((item) => item.id === selectedBranch)}
            />
          </div>
          <div
            className="h-96 lg:h-[634px] mb-10 lg:mb-[70px] customIframe"
            dangerouslySetInnerHTML={{
              __html: storeInfos?.find((item) => item.id === selectedBranch)
                ?.map_URL,
            }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OurStorePage;
